import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Link, Stack
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
//import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface ConstructionPermitAddEditModalProps {
  state: App.ConstructionState;
  onChange?: Function;
}


export class ConstructionPermitAddEditModal extends React.Component<ConstructionPermitAddEditModalProps> {

  // todo public designSetDataService: App.ConstructionPermitDataService;
  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    // todo this.designSetDataService = new App.ConstructionPermitDataService();
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    // console.log('ConstructionPermitAddEditModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('ConstructionPermitAddEditModal componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onAddOrUpdateClick(isPublished: boolean) {
    console.log('onAddOrUpdateClick');


    var state = this.props.state;
    var vm = state.vm;
    var sm = state.permitSm;
    var item = vm.selectedConstructionPermit;
    var items = state.permits;

    //todo item.isPublished = isPublished;

    var isValid = this.validationManager.validateConstructionPermitCreateUpdate(null, item);

    if (!isValid) {
      console.log('validateAddConstructionPermitModal NOT valid, ErrorMessage:', App.AppBase.lastErrorMessage);
      this.forceUpdate();
      return;
    }

    if (vm.isConstructionPermitsEditOpen)
      App.AppStore.dispatch(App.ActionTypes.CONSTRUCTION_PERMIT_UPDATE_REQUEST, item);
    else if (vm.isConstructionPermitsAddOpen)
      App.AppStore.dispatch(App.ActionTypes.CONSTRUCTION_PERMIT_ADD_REQUEST, item);

    vm.isActionInProgress = true;

    // this.onCloseClick();

  }

  public onCloseClick() {
    console.log('onCloseClick');
    var state = this.props.state;
    var vm = state.vm;
    var sm = state.permitSm;

    //  console.log('onCloseClick selectedConstructionPermitOriginal',vm.selectedConstructionPermitOriginal);

    // state.permits[0] =  vm.selectedConstructionPermitOriginal;

    //reset edits
    App.ConstructionPermitManager.update(vm.selectedConstructionPermitOriginal, state.permits);

    vm.isConstructionPermitsAddOpen = false;
    vm.isConstructionPermitsEditOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

    // console.log('onCloseClick onChange');

    if (this.props.onChange !== undefined)
      this.props.onChange();

    // console.log('onCloseClick end');

  }

  public onAutocompleteChange(name: string, value: any, selectedTypes: any, valueProperty: any = '') {
    console.log('onAutocompleteChange name', name);
    console.log('onAutocompleteChange value', value);
    console.log('onAutocompleteChange selectedTypes', selectedTypes);

    var state = this.props.state;
    var vm = state.vm;

    var sm = state.permitSm;

    var item = vm.selectedConstructionPermit;

    var key = LIB.FormHelper.setAutocompleteChangeForIdAndType(name, item, selectedTypes, valueProperty);

    console.log('onAutocompleteChange setAutocompleteChangeForIdAndType key', key);
    console.log('onAutocompleteChange selectedItem', item);



    /* if (name == 'name')
      item.name = value;

    else {

      name = LIB.FormHelper.setInputChangeData(selected, item);

      if (LIB.Common.isNullOrEmpty(name))
        return;
    }
 */
    this.validationManager.validateConstructionPermitCreateUpdate(key, item);

    this.forceUpdate();
  }


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedConstructionPermit;

    var target: any = e.target;

    var name = LIB.FormHelper.setInputChangeData(e.target, item);

    if (LIB.Common.isNullOrEmpty(name))
      return;


    this.validationManager.validateConstructionPermitCreateUpdate(name, item);


    this.forceUpdate();
  }

  /* 
    public onFileChange(fileInfo: LIB.MSingleFileUploadVm) {
      console.log('onFileChange fileInfo', fileInfo);
  
      var state = this.props.state;
      var vm = state.vm;
      var item = vm.selectedConstructionPermit;
  
      if (LIB.Common.isNullOrEmpty(fileInfo)) {
        console.log('onFileChange isNullOrEmpty', fileInfo);
  
        if (item.resume != undefined) {
          item.resume.file = undefined;
          item.resume.filePath = undefined;
          item.resume.fileName = '';
          item.resume.constructionPermitId = undefined;
        }
  
        item.resumeFile = undefined;
  
        console.log('onFileChange resume reset', item?.resume);
  
      }
      else {
  
  
        if (item.resume == undefined)
          item.resume = new Api.Attachment();
  
        item.resume.file = fileInfo.newFile;
        item.resumeFile = fileInfo.newFile;
        item.resume.fileName = fileInfo.newFileName;
        item.resume.constructionPermitId = item.constructionPermitId;
        item.resume.attachmentRecordTypeId = App.AttachmentRecordTypeEnum.ConstructionPermit;
        item.resume.attachmentTypeId = App.AttachmentTypeEnum.Resume;
      }
  
      this.validationManager.validateConstructionPermitCreateUpdate('resume', item);
  
      var tst = LIB.FormDataHelper.getFormData(item);
  
      console.log('onFileChange item', item);
      console.log('onFileChange tst', tst);
  
    }
  
   
    public getResumeUploadElement() {
  
  
      var state = this.props.state;
      var vm = state.vm;
      var item = vm.selectedConstructionPermit;
  
      var fileVm = new LIB.MSingleFileUploadVm()
  
      fileVm.existingFileName = item.resume?.fileName;
      fileVm.existingFilePath = `${App.AppBase.appApiBaseUrl}/files/${item.resume?.filePath}`;
  
      return <div>
  
        <h3>Resume</h3>
  
        <LIB.MSingleFileUpload
          name='resume'
          vm={fileVm}
          onChange={(fl: LIB.MSingleFileUploadVm) => this.onFileChange(fl)}
          pending={(state.isConstructionPermitCreateUpdatePending)}
  
        />
   
      </div>
    } */


  public getForm() {
    //console.log('ConstructionPermitAddEditModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedConstructionPermit;


    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };

    var item = vm.selectedConstructionPermit;

    if (item === undefined)
      return null;

    //  var statusTag = App.FeaturedManager.getStatusNameTag(item);

    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasConstructionPermitDataLoaded}
      loading={(state.isConstructionPermitCreateUpdatePending || vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      <form     >



        <div >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                variant='standard'
                name="permitNumber" label="Permit"
                value={item.permitNumber || ''}
                {...App.AppBase.getInputErrorProps('permitNumber')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
              />
            </Grid>
            <Grid item xs={12} sm={6}
            >
              <TextField
                name="address" label="Address"
                //placeholder="i.e Full Stack .NET/React Developer"
                value={item.address || ''}
                {...App.AppBase.getInputErrorProps('address')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

            </Grid>

          </Grid>


          <div>

            <TextField
              name="workDescription" label="Description"
              value={item.workDescription || ''}
              {...App.AppBase.getInputErrorProps('workDescription')}
              onChange={this.onInputChange.bind(this)}
              style={fullWidth}
              //placeholder="1 or 2 main skills, i.e .Net, React"
              inputProps={{ style: formInput }}
              rows={5}
              multiline
            />


          </div>

          <div>

<TextField
  name="notes" label="Notes"
  value={item.notes || ''}
  {...App.AppBase.getInputErrorProps('notes')}
  onChange={this.onInputChange.bind(this)}
  style={fullWidth}
  //placeholder="1 or 2 main skills, i.e .Net, React"
  inputProps={{ style: formInput }}
  rows={3}
  multiline
/>


</div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                variant='standard'
                name="permitStatus" label="Permit Status"
                value={item.permitStatus || ''}
                {...App.AppBase.getInputErrorProps('permitStatus')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
              />
            </Grid>
            <Grid item xs={12} sm={6}
            >
              <TextField
                name="permitIssuedDate" label="Issued Date"
                value={item.permitIssuedDate || ''}
                {...App.AppBase.getInputErrorProps('permitIssuedDate')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

            </Grid>

          </Grid>



          <div>

          </div>

<Grid container spacing={2}>
  <Grid item xs={12} sm={6} >
    <TextField
      variant='standard'
      name="ownerName" label="Owner Name"
      value={item.ownerName || ''}
      {...App.AppBase.getInputErrorProps('ownerName')}
      onChange={this.onInputChange.bind(this)}
      style={fullWidth}
      inputProps={{ style: formInput }}
    />
  </Grid>
  <Grid item xs={12} sm={6}
  >
    <TextField
      name="ownerPhone" label="Owner Phone"
      value={item.ownerPhone || ''}
      {...App.AppBase.getInputErrorProps('ownerPhone')}
      onChange={this.onInputChange.bind(this)}
      style={fullWidth}
      inputProps={{ style: formInput }}

    />

  </Grid>

</Grid>



<div>



            <FormControlLabel
              control={

                <Switch name="isHot"
                  checked={item.hasPendingInspections}
                  onChange={this.onInputChange.bind(this)}
                />

              }
              style={{ marginLeft: 0 }}
              labelPlacement="start"
              label="Has Pending Inspections"
            />

            <FormControlLabel
              control={

                <Switch name="isPublished"
                  checked={item.hasInspectionHistory}
                  onChange={this.onInputChange.bind(this)}

                  color="primary"
                />

              }
              style={{ marginLeft: 20 }}
              labelPlacement="start"
              label="Has Inspections"
            />



          </div>


          {/*  {this.getResumeUploadElement()}
 */}

        </div>


      </form>

    </App.MainPage>;


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    //console.log('ConstructionPermitAddEditModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = (vm.isConstructionPermitsAddOpen || vm.isConstructionPermitsEditOpen);
    var item = vm.selectedConstructionPermit;


    var title = "Add Permit";
    var saveBtnTitle = "Save Draft"; //"Add";
    if (vm.isConstructionPermitsEditOpen) {
      title = `Edit Permit: ${item.permitNumber} (${item.constructionPermitId})`;
      saveBtnTitle = "Save Draft";
    }

    var statusTag = App.FeaturedManager.getStatusNameTag(item);

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >{title} {statusTag}</DialogTitle>
          <DialogContent style={{ width: '35vw', minWidth: 800 }}>
            {/* {this.getAlert(App.AppBase.lastErrorMessage)} */}

            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <div style={{ marginRight: 10 }}>Status: {statusTag}</div>

            <Button
              variant="contained"
              color="inherit"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</Button>

         {/*    <Button
              color="warning"
              variant="contained"
              style={{ margin: 10 }}
              onClick={() => this.onAddOrUpdateClick(false)}
              disabled={vm.isActionInProgress}
            >{saveBtnTitle}</Button>
 */}
  
              <Button
                color="primary"
                variant="contained"
                style={{ margin: 10 }}
                //onClick={() => this.onAddOrUpdateClick(true)}
                onClick={() => this.onAddOrUpdateClick(false)}
                disabled={vm.isActionInProgress}
              >Save</Button>

            

          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 