import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class ConstructionPermitReducers {


    constructor() {

    }

    public static getReducers(state: App.ConstructionState | undefined, action: App.AppAction): App.ConstructionState {
        var str = "";

        var result = new App.ConstructionState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("CONSTRUCTION_PERMIT"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        var constructionService = new App.ConstructionService();
        var attachmentService = new App.AttachmentService();
    

        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.CONSTRUCTION_PERMIT_API_ERROR:{
                console.log('CONSTRUCTION_PERMIT_API_ERROR:');
                console.log('action.payload:', action);
                result = { ...state };
                var vm = result.vm;
                vm.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action?.payload);
                result.forceUpdateCount++;
                result.isConstructionPermitCreateUpdatePending = false;
                vm.isActionInProgress = false;
                break;
            }
            case App.ActionTypes.CONSTRUCTION_PERMIT_LIST_DATA_REQUEST: {
                console.log('CONSTRUCTION_PERMIT_LIST_DATA_REQUEST:', action.payload);
                result =  { ...state };
                result.apiErrorMessage='';
               

                var searchFilter = action.payload;

                constructionService.getConstructionPermitListData(searchFilter);


                result.isServiceCallPending = true;
                result.forceUpdateCount++;

                 break;
            }   
            case App.ActionTypes.CONSTRUCTION_PERMIT_LIST_DATA_RECEIVED: {

                console.log('CONSTRUCTION_PERMIT_LIST_DATA_RECEIVED:',action.payload);
                result = { ...state };
                let vm = result.vm;
                let filter  = result.permitSearchFilter;
                
                result.permits = action.payload.permits;
                result.permitStatuses = action.payload.permitStatuses;

                 

                LIB.ObjectHelper.setData(action.payload,result);

                 if(!result.hasConstructionPermitDataLoaded){
                    //LIB.ObjectHelper.setData(action.payload,result);

                   // result.permitSearchFilter = action.payload.searchFilter;
                    LIB.ObjectHelper.setData(action.payload.searchFilter,result.permitSearchFilter);
                                       
                }
                console.log('CONSTRUCTION_PERMIT_LIST_DATA_RECEIVED permitSearchFilter:',result.permitSearchFilter);

                if(result.permits.length==1){
                    vm.selectedConstructionPermit=result.permits[0];
                    
                }
                else{
                    result.hasConstructionPermitDataLoaded = true;
                }

                
                setTimeout(() => LIB.Common.scrollToTop(undefined), 100);
                result.isServiceCallPending = false;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.CONSTRUCTION_PERMIT_ADD_REQUEST: {
                console.log('CONSTRUCTION_PERMIT_ADD_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;

                vm.setSelectedConstructionPermit(data);
                constructionService.createConstructionPermit(data);

                result.isConstructionPermitCreateUpdatePending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.CONSTRUCTION_PERMIT_ADD_SUCCESS: {
                console.log('CONSTRUCTION_PERMIT_ADD_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = {... action.payload };

                result.permits.splice(0, 0, item); //push at the beginning
                
               // result.permits.push(item);
 
                vm.isConstructionPermitsAddOpen=false;
                vm.isActionInProgress=false;
                vm.apiErrorMessage='';
                result.isConstructionPermitCreateUpdatePending = false;
               // item.resume = vm.selectedConstructionPermit?.resume;
                vm.setSelectedConstructionPermit(item);
                  
               //console.log('CONSTRUCTION_PERMIT_ADD_REQUEST permits:', result.permits);
               result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.CONSTRUCTION_PERMIT_UPDATE_REQUEST: {
                console.log('CONSTRUCTION_PERMIT_UPDATE_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                constructionService.updateConstructionPermit(data);

                App.AppBase.showSaveSuccess();
                
                result.isConstructionPermitCreateUpdatePending = true;
                result.forceUpdateCount++;
                console.log('CONSTRUCTION_PERMIT_UPDATE_REQUEST isConstructionPermitCreateUpdatePending:',result.isConstructionPermitCreateUpdatePending);
                break;
            }   
            case App.ActionTypes.CONSTRUCTION_PERMIT_UPDATE_SUCCESS: {
                console.log('CONSTRUCTION_PERMIT_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = {... action.payload };
               

                  
                var updated =  App.ConstructionPermitManager.update(item,result.permits);
            
                vm.isConstructionPermitsEditOpen=false;


                vm.isActionInProgress=false;
                vm.apiErrorMessage='';
                result.isConstructionPermitCreateUpdatePending = false;
                vm.setSelectedConstructionPermit(item);
                  
               // console.log('CONSTRUCTION_PERMIT_UPDATE_SUCCESS vm:',vm);


                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.CONSTRUCTION_PERMIT_DELETE_REQUEST: {
                console.log('CONSTRUCTION_PERMIT_DELETE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var itemId = action.payload;

                constructionService.deleteConstructionPermit(itemId);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }   
            
            case App.ActionTypes.CONSTRUCTION_PERMIT_DELETE_SUCCESS: {
                console.log('CONSTRUCTION_PERMIT_DELETE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

                var itemId:any = vm.selectedConstructionPermit?.constructionPermitId;
                var success = action.payload;
 
                if(success)
                    App.ConstructionPermitManager.deleteById(itemId,result.permits);
                
                vm.isConstructionPermitsDeleteOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                vm.setSelectedConstructionPermit();
                  
                result.forceUpdateCount++;
                break;
            }   

            
           
            case App.ActionTypes.CONSTRUCTION_PERMIT_UPLOAD_REQUEST: {
                console.log('CONSTRUCTION_PERMIT_UPLOAD_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                let item = action.payload;
               
                 item.constructionPermitId = vm.selectedConstructionPermit?.constructionPermitId;
                 item.recordId = vm.selectedConstructionPermit?.constructionPermitId;

                 if(LIB.Common.isNullOrEmpty(item?.file)){
                    result.apiErrorMessage="CONSTRUCTION_PERMIT_UPLOAD_REQUEST file is empty!";
                    console.error(result.apiErrorMessage);
                    result.forceUpdateCount++;
                    break;
                }

                attachmentService.candidateUpload(item);

                result.isConstructionPermitCreateUpdatePending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.CONSTRUCTION_PERMIT_UPLOAD_SUCCESS: {
                console.log('CONSTRUCTION_PERMIT_UPLOAD_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

              //  var itemId:any = vm.selectedConstructionPermit?.constructionPermitId;
                var data = action.payload; 
               // vm.selectedConstructionPermit.resume = data;


                let item = result.permits.find((c) => c.constructionPermitId === data.constructionPermitId);
                console.log('CONSTRUCTION_PERMIT_UPLOAD_SUCCESS: item', item);
                console.log('CONSTRUCTION_PERMIT_UPLOAD_SUCCESS: data.constructionPermitId', data.constructionPermitId);
                console.log('CONSTRUCTION_PERMIT_UPLOAD_SUCCESS: result.permits', result.permits);
                //let item = result.permits[index];


             /*  todo
               if (item){
                    item.resume = data;
                } */
                    
                
                console.log('CONSTRUCTION_PERMIT_UPLOAD_SUCCESS vm.selectedConstructionPermit:', vm.selectedConstructionPermit);

                vm.isConstructionPermitsEditOpen=false;
                vm.isConstructionPermitsAddOpen=false;
                result.isConstructionPermitCreateUpdatePending = false;
                vm.setSelectedConstructionPermit();
                  
                result.forceUpdateCount++;
                break;
            }   
           
            
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        

        return result;
    }



}