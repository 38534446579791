import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import { Alert } from '@mui/material';


interface CompanyProfileProps {
  state: App.AccountState;
  children?: React.ReactNode;

}

export class CompanyProfile extends React.Component<CompanyProfileProps> {

  public validationManager: App.ValidationManager;
  public profileManager: App.ProfileManager;
  public jobService: App.JobService;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();
    this.profileManager = new App.ProfileManager();
  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('CompanyProfile componentDidMount');

  }

  public resetData() {
    console.log('CompanyProfile resetData');
    var item = this.props.state.company;


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    //console.log('CompanyProfile componentDidUpdate');


  }

  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    const item = this.props.state.company;
    var target: any = e.target;

    // item.firstName='bb';
    // console.log('onInputChange value', target.value);
    // console.log('onInputChange item', item);

    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    //console.log('onInputChange key', key);
    //
    console.log('onInputChange value2', target.value);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateCompanyProfileEdit(key, item);

    //console.log('onInputChange item', item);

    this.forceUpdate();
  }


  public onDateChange(date: any | null, value?: string | null) {

    console.log(date);
    console.log(value);

  }


  
  public onAutocompleteChange(name: string, value: any, item: any) {
    console.log('AreasImpactedUpdateModal onAutocompleteChange');

    var idProperty = name + "Id";

    const selectedItem = this.props.state.company;


    if (LIB.Common.isNullOrEmpty(selectedItem)) {
      console.log('selectedItem is empty!', item);
      return;
    }

    //set id
    var selectedId = { name: idProperty, value: value }
    var key = LIB.FormHelper.setInputChangeData(selectedId, selectedItem);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    //set type
    var selectedType = { name: name, value: item }
    var key2 = LIB.FormHelper.setInputChangeData(selectedType, selectedItem);

    this.validationManager.validateCompanyProfileEdit(key2, selectedItem);
 
   
   /*  console.log('onAutocompleteChange selectedId:', selectedId);
    console.log('onAutocompleteChange selectedType:', selectedType);
    console.log('onAutocompleteChange value:', value);
    console.log('onAutocompleteChange key:', key);
    console.log('onAutocompleteChange key2:', key2);
    console.log('onAutocompleteChange selectedItem:', selectedItem);  */

    this.forceUpdate();
   
  }



  public onAutocompleteAddressChange(name: string, value: any, details: any) {
    console.log('onAutocompleteAddressChange');

    const item = this.props.state.company;

    var selected = {
      name: name,
      value: value
    }

    console.log('onAutocompleteAddressChange selected', selected);


    var key = LIB.FormHelper.setInputChangeData(selected, item);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateCompanyProfileEdit(key, item);


    this.forceUpdate();
  }
 
  public onCloseClick() {

    this.resetData();
    App.RouteManager.routeToPath('/')

  }


  public onSaveClick(e: React.ChangeEvent<{}>) {
    console.log('onSaveClick');


    var state = this.props.state;
    var vm = state.vm;
    var company = state.company;

    var isValid = this.validationManager.validateCompanyProfileEdit(null, company);

    if (!isValid) {
      console.log('onSaveClick CompanyProfile NOT valid');
      this.forceUpdate();
      return;
    }

    var sm = new Api.AccountUpdateCompanySm();
    sm.company = company;
    App.AppStore.dispatch(App.ActionTypes.ACCOUNT_COMPANY_UPDATE_REQUEST, sm);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }


  
  public getIncompleteAlert(){
    var state = this.props.state;
    var item = state.company;

   // console.log('getIncompleteAlert hasDataLoaded', state.hasDataLoaded);
   // console.log('getIncompleteAlert isCompanyDetailsComplete', item.isCompanyDetailsComplete);

    if(state.hasDataLoaded && !item.isCompanyDetailsComplete)
       return <Alert severity="error">Please complete the Company profile to Continue! </Alert>;

    return null;
  }

  public render() {

    //console.log('ProjectScopeForm render');

    var state = this.props.state;
    var item = state.company;


    var user = state.profile;//App.AppBase.currentUser;
    //console.log('render user',user);

    //if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || LIB.Common.isNullOrEmpty(item))
    //  return null;//<LIB.Loading />;

    var theme = App.AppBase.theme;

    var formControl: CSSProperties = {
      margin: theme.spacing(2),
      //minWidth:'25rem',
      width: '100%',
      paddingRight: 20,
      marginLeft: 0,
      // padding:'20px, 16px'
      marginTop: 0
    };
    var fullWidth: CSSProperties = {
      margin: theme.spacing(2),
      paddingRight: 20,
      marginLeft: 0


    };

    var formInput: CSSProperties = {

      height: 50,
      //padding:15
      // padding:'20px, 16px'
    };

    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20 }

    };

    var textFieldStyle = { paddingTop: 20, marginBottom: 10 };


    var formControlHalf = App.StyleHelper.getFormControlHalfWidthStyle();

    var countries = LIB.LocationCountryHelper.getCountries();


    return <div style={{ width: '40vw', marginLeft: 10 }}>
    
      {this.getIncompleteAlert()}

      <h2 >Company Profile</h2>

      <form >

        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} >
            <TextField
              name="companyName" label="Company Name"
              value={item.companyName || ''}
              {...App.AppBase.getInputErrorProps('companyName')}
              onChange={this.onInputChange.bind(this)}
              style={formControl}
              inputProps={{ style: formInput }}
            />
          </Grid>
          <Grid item xs={12} sm={6}

          >
            <TextField
              name="phone" label="Company Phone"
              value={item.phone || ''}
              {...App.AppBase.getInputErrorProps('phone')}
              onChange={this.onInputChange.bind(this)}
              style={formControl}
              inputProps={{ style: formInput }}

            />
          </Grid>
        </Grid>

        <App.FullAddress
          address={item.address}
          city={item.city}
          state={item.state}
          zipCode={item.zipCode}
          country={item.country}
          onInputChange={this.onInputChange.bind(this)}
          onAutocompleteChange={this.onAutocompleteAddressChange.bind(this)}
        />

        <App.FormAutocomplete
          label="Company Size?"
          name="companySizeType"
          valueProperty='lookupTypeId'
          labelProperty="name"
          data={state.companySizeTypes}
          onChange={this.onAutocompleteChange.bind(this)}
          value={this.profileManager.getLookupById(item.companySizeTypeId, state.companySizeTypes)}
          textFieldClassName="standardAutocompleteTextField"

        />

        {/* <App.FormAutocomplete
          label="How many recruiters do you have at the company?"
          name="companySizeRecruitersType"
          valueProperty='lookupTypeId'
          labelProperty="name"
          data={state.companySizeRecruitersTypes}
          onChange={this.onAutocompleteChange.bind(this)}
          value={this.profileManager.getLookupById(item.companySizeRecruitersTypeId, state.companySizeRecruitersTypes)}
          textFieldClassName="standardAutocompleteTextField"

        /> */}


    {/*     <App.FormAutocomplete
          label="How many ConstructionPermits do does the company have on Bench? "
          name="avgBenchTalentCountType"
          valueProperty='lookupTypeId'
          labelProperty="name"
          data={state.avgBenchTalentCountTypes}
          onChange={this.onAutocompleteChange.bind(this)}
          value={this.profileManager.getLookupById(item.avgBenchTalentCountTypeId, state.avgBenchTalentCountTypes)}
          textFieldClassName="standardAutocompleteTextField"

        /> */}



        {/* 

        <h3>Main Company Contact Person Info:</h3>
        
        <Grid container spacing={2}>
       
        <Grid item xs={12} sm={3} >
            <TextField
              name="contactFirstName" label="First Name"
              value={item.contactFirstName || ''}
              {...App.AppBase.getInputErrorProps('contactFirstName')}
              onChange={this.onInputChange.bind(this)}
              style={formControl}
              inputProps={{ style: formInput }}
            />
          </Grid>
          <Grid item xs={12} sm={3} >
            <TextField
              name="contactLastName" label="Last Name"
              value={item.contactLastName || ''}
              {...App.AppBase.getInputErrorProps('contactLastName')}
              onChange={this.onInputChange.bind(this)}
              style={formControl}
              inputProps={{ style: formInput }}
            />
          </Grid>
          <Grid item xs={12} sm={3} >
            <TextField
              name="contactEmail" label="Email"
              value={item.contactEmail || ''}
              {...App.AppBase.getInputErrorProps('contactEmail')}
              style={formControl}
              inputProps={{ style: formInput }}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12} sm={3} >
            <TextField
              name="contactPhone" label="Phone"
              value={item.contactPhone || ''}
              {...App.AppBase.getInputErrorProps('contactPhone')}
              style={formControl}
              inputProps={{ style: formInput }}
              disabled={true}
            />
          </Grid>
         
        </Grid> */}


        <div style={{ marginTop: 50 }}>
          <Button
            id="btnCancel"
            variant="contained"
           // //color="default"
            disabled={!item.isCompanyDetailsComplete}
            onClick={() => this.onCloseClick()}
          >Close</Button>

          <Button
            id="btnSave"
            color="primary"
            variant="contained"
            style={{ marginLeft: 15 }}
            onClick={this.onSaveClick.bind(this)}

          >Save</Button>

        </div>

      </form>


      {this.props.children}
    </div>;


  }
};
