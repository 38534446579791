import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Menu, Button, Grid, Divider } from '@mui/material';
import * as App from 'AppReferences';
//import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as LIB from '_LIB';
import { Tabs, Tab,Box,Typography } from "@mui/material";
 

import { createStyles,  Theme } from '@mui/material/styles';
import { useParams, useLocation } from 'react-router';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import { styled } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { CSSProperties } from "react";

//import './AccountMenuItem.css'


 
type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  iconColor?:string;
  isroot?:string;
  to?:string;
};


const StyledTreeItemMain = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary, width:'100%',
 
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    //paddingTop:5,
    
   // borderTopRightRadius: theme.spacing(2),
   // borderBottomRightRadius: theme.spacing(2),
     paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
     // backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: -18,//adjust for submenu items only
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2) 
      
    },
  },

}));

export const StyledTreeItem: React.FC<StyledTreeItemProps> = (props) => {

  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
  
    labelInfo,
    labelText,
    iconColor,
    to,
    ...other

  } = props;
 
 
 var marginLeft = 0.5;

 if(props.isroot)
    marginLeft=-1;

  //  console.log('StyledTreeItem id:',props.nodeId);

   return (
    <StyledTreeItemMain key={props.nodeId}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0, marginLeft:marginLeft }}>
          <Box component={LabelIcon} color={iconColor} sx={{ mr: 2}} />
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1, fontSize: 15   }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
        //padding:0, margin:0
      }}
      {...other}
    />
  );  

}