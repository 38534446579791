import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdministrationUserReducers {


    constructor() {

    }

    public static getReducers(state: App.AdministrationState | undefined, action: App.AppAction): App.AdministrationState {
        var str = "";

        var result = new App.AdministrationState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ADMINISTRATION_USERS"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

       var adminUsersService = new App.AdminUsersService();


        // const action = incomingAction as KnownAction;
        switch (action.type) {
 
            case App.ActionTypes.ADMINISTRATION_USERS_LIST_PAGE_DATA_REQUEST: {
                console.log('ADMINISTRATION_USERS_LIST_PAGE_DATA_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var searchFilter = action.payload;
                adminUsersService.getUsersListData(searchFilter);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
 
            case App.ActionTypes.ADMINISTRATION_USERS_LIST_DATA_RECEIVED: {
                console.log('ADMINISTRATION_USERS_LIST_DATA_RECEIVED:');
                result = { ...state };
                if (LIB.Common.isNullOrEmpty(result.appUsersSearchFilter?.keyword)) {
                    result.appUsersFullList = action.payload.appUsers;
                }
                result.appUsers = action.payload.appUsers;
                result.appUsersCount = action.payload.appUsersCount;
                result.userRoleTypes = action.payload.userRoleTypes;

                result.isServiceCallPending = false;
               result.hasUserDataLoaded = true;
                console.log("result" , result);
                break;
            }
            case App.ActionTypes.ADMINISTRATION_USERS_LIST_PAGE_RESET: {
                console.log('ADMINISTRATION_USERS_LIST_PAGE_RESET:');
                result = { ...state };
                result.appUsers = result.appUsersFullList;
                result.appUsersSearchFilter.keyword = '';
               // result.isServiceCallPending = false;
             //  result.hasUserDataLoaded = true;
                console.log("result" , result);
                break;
            }
            case App.ActionTypes.ADMINISTRATION_USERS_UPDATE_REQUEST: {
                console.log('ADMINISTRATION_USERS_UPDATE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                adminUsersService.updateUser(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_USERS_UPDATE_SUCCESS: {
                console.log('ADMINISTRATION_USERS_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = {... action.payload };
               
                App.AdministrationManager.update(item,result.appUsers);
                
                vm.apiErrorMessage=''; //to clear any api error msg
                vm.isUsersEditOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                vm.setSelectedUser(item);
                  
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_USERS_ADD_REQUEST: {
                console.log('ADMINISTRATION_USERS_ADD_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                adminUsersService.createUser(data);
                result.isServiceCallPending = true;
                //result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_USERS_ADD_SUCCESS: {
                console.log('ADMINISTRATION_USERS_ADD_SUCCESS:', action.payload);

                result = { ...state };
                var vm = result.vm; 
                var item = {... action.payload };
                              //  var item = vm.;

                console.log("item pushed: ", (item));
               // console.log(item.constructor.name); 

                result.appUsers.splice(0, 0, item); //push at the beginning
               // result.permits.push(item);
               vm.apiErrorMessage='';
                vm.isUsersAddOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;

                vm.setSelectedUser();
                console.log("vm.setSelectedUser();:, ", vm.selectedUser);
                result.adminCreateUserFormSm = new Api.AdminCreateUserFormSm();

               //console.log('CONSTRUCTION_PERMIT_ADD_REQUEST permits:', result.permits);
               result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_USERS_DELETE_REQUEST: {
                console.log('ADMINISTRATION_USERS_DELETE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var itemId = action.payload;

                adminUsersService.deleteUser(itemId);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_USERS_DELETE_SUCCESS: {
                console.log('ADMINISTRATION_USERS_DELETE_SUCCESS:', action.payload);
               // result = state;//{ ...state };
                result = { ...state };
                var vm = result.vm;
 
                var itemId:any = vm.selectedUser?.userId;
                var success = action.payload;
 
                if(success){
                    //console.log('ADMINISTRATION_USERS_DELETE_SUCCESS success:',success);
                   // console.log('ADMINISTRATION_USERS_DELETE_SUCCESS itemId:',itemId);
                    App.AdministrationManager.deleteById(itemId,result.appUsers);

                }
                
                vm.isUsersDeleteOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;

 
                vm.setSelectedUser();  

 
                result.forceUpdateCount++;
                break;
            }   
            
            case App.ActionTypes.ADMINISTRATION_USERS_LIST_PAGE_DATA_REQUEST: {
                console.log('ADMINISTRATION_USERS_LIST_PAGE_DATA_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var searchFilter = action.payload;
                adminUsersService.getUsersListData(searchFilter);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }

            case App.ActionTypes.ADMINISTRATION_USERS_PASSWORD_CHANGE_REQUEST:{
                console.log('ADMINISTRATION_USERS_PASSWORD_CHANGE_REQUEST:', action.payload);
                result = state;//{ ...state };
               
                var data = action.payload; 

                adminUsersService.changePassword(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            } 
            case App.ActionTypes.ADMINISTRATION_USERS_PASSWORD_CHANGE_SUCCESS: {
                console.log('ADMINISTRATION_USERS_PASSWORD_CHANGE_SUCCESS:', action.payload);
                result = { ...state };
                let vm = result.vm;
                var userId = vm.adminPasswordChangeSm.userId;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                vm.adminPasswordChangeSm = new Api.AdminPasswordChangeSm();
                vm.isUsersChangePasswordOpen = false;
                result.forceUpdateCount++;
                App.AppBase.showMessage(`Password Successfully Updated For ID ${userId}`);

                break;
            }   
            case App.ActionTypes.ADMINISTRATION_USERS_ROLE_CHANGE_REQUEST:{
                console.log('ADMINISTRATION_USERS_ROLE_CHANGE_REQUEST:', action.payload);
                result = { ...state };
                let vm = result.vm;
                var data = action.payload;  
                adminUsersService.changeUserRoles(data);
                vm.isActionInProgress=true;
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            } 
            case App.ActionTypes.ADMINISTRATION_USERS_ROLE_CHANGE_SUCCESS: {
                console.log('ADMINISTRATION_USERS_ROLE_CHANGE_SUCCESS:', action.payload);
                result = { ...state };
                let vm = result.vm;
                let item = (action.payload as Api.AppUser);
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                let userId = vm.adminChangeUserRolesSm?.userId;

                App.UserManager.update(item, state.appUsers)

               /*  var selectedUser = vm.selectedUser; //result.appUsers.find(x=>x.userId===userId);
                console.log('ADMINISTRATION_USERS_ROLE_CHANGE_SUCCESS selectedUser:', selectedUser);
                
                selectedUser.userRoles = data;
               
                console.log('ADMINISTRATION_USERS_ROLE_CHANGE_SUCCESS userRoles:', selectedUser.userRoles);
                console.log('ADMINISTRATION_USERS_ROLE_CHANGE_SUCCESS userRoleTypes:', selectedUser.userRoleTypes);
                selectedUser.userRoleTypes = result.userRoleTypes.filter(x=> selectedUser.userRoles?.findIndex(x=>x.roleId==)>-1);
 */
              //  vm.adminChangeUserRolesSm = new Api.AdminPasswordChangeSm();
                vm.isUsersEditRolesOpen = false;
                result.forceUpdateCount++;
                App.AppBase.showMessage(`Roles Successfully Updated!`);

                break;
            }   

            
            
            default:
                result = state;
                //return result;
                break;
        }
       // console.log('ADMINISTRATION_USERS_DATA_RECEIVED result2:',result);
      //  console.log('administrationuser_DATA_RECEIVED action:',action);


        if (action.postActionCallBack){
            console.log("postactioncallback");
                action.postActionCallBack(result);
            }
        return result;
    }



}