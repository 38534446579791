//https://v4.mui.com/components/tree-view/#tree-view

import React from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import Label from '@mui/icons-material/Label';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SvgIconProps } from '@mui/material/SvgIcon';

import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';
import AdminIcon from '@mui/icons-material/PeopleAlt';
import { Drawer, Toolbar, List, Divider, ListItem, ListItemIcon, ListItemText, Button, Box } from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import { BrowserRouter, Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import * as App from 'AppReferences';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import { styled } from '@mui/material/styles';
import { CSSProperties } from "react";
import { connect } from 'react-redux';

import * as LIB from '_LIB';
import * as Api from 'app-api';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}


interface SOTreeMenuProps {
  rootMenuId:string;
  params: any;
  location: any;
  state: App.GlobalState
  items:any[];
}
export const SOTreeMenu: React.FC<SOTreeMenuProps> = (props) => {
  
  var vm = props.state.globalUIState;
  var selectedTab: string | undefined;
  var locationState = props.location.state;
  var menuItems = props.items;
  var rootMenuId = props.rootMenuId;
  //const [, updateState] = React.useState({});
  //const forceUpdate = React.useCallback(() => updateState({}), []);


  React.useEffect(() => {
    console.log("SOTreeMenu useEffect update");
   
    checkSetSelected();
  },[]);



  function checkSetSelected() {
    console.log("SOTreeMenu checkSetSelected, vm", vm);

   /*  console.log("SOTreeMenu checkSetSelected params:", props.params);
    console.log("SOTreeMenu checkSetSelected location:", props.location);
    console.log("SOTreeMenu checkSetSelected selectedConstructionRootMenuIds 1:", vm.selectedConstructionRootMenuIds);
 */
 
    var pathname = props.location?.pathname;
    var changed: boolean = selectOpenRoot(pathname);
 
   // console.log("SOTreeMenu checkSetSelected selectedConstructionRootMenuIds 2:", vm.selectedConstructionRootMenuIds);


    var isMenuItem = (menuItems.findIndex(x => x.props.nodeId == pathname) > -1);

    // console.log("SOTreeMenu checkSetSelected isMenuItem:",isMenuItem,menuItems);

 
    if (isMenuItem && !vm.selectedConstructionMenuIds?.includes(pathname)) {
     /*  console.log("SOTreeMenu checkSetSelected Menu Item Changed true:", vm.selectedConstructionMenuIds);
      console.log("SOTreeMenu checkSetSelected Menu Item Changed pathname:", pathname);
 */
      vm.selectedConstructionMenuIds = [ pathname ];
      changed = true;
    }
    else if (!isMenuItem) {
      vm.selectedConstructionMenuIds = [];
    }



    if (changed) {
      console.log("SOTreeMenu checkSetSelected changed:", changed);
      App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);
      //forceUpdate();
    }

  }


  function selectOpenRoot(nodeId:any): boolean {

    var changed: boolean = false;
 
    console.log("SOTreeMenu selectOpenRoot nodeId:", nodeId);
    console.log("SOTreeMenu selectOpenRoot selectedConstructionRootMenuIds 0:", vm.selectedConstructionRootMenuIds);
    console.log("SOTreeMenu selectOpenRoot rootMenuId:",rootMenuId);

    if ((nodeId?.includes(rootMenuId) || nodeId?.includes('/Account/Profile')) && vm.selectedConstructionRootMenuIds != rootMenuId) {
      vm.selectedConstructionRootMenuIds = rootMenuId;
      console.log("SOTreeMenu selectOpenRoot Construction Root Open: YES");
      changed = true;
    }
    else if(vm.selectedConstructionRootMenuIds == rootMenuId) {
      console.log("SOTreeMenu selectOpenRoot Construction Root Open/Close",vm.selectedConstructionRootMenuIds);
      changed = true;
     // vm.selectedConstructionRootMenuIds = '';
    
    }
    else  {
      
     vm.selectedConstructionRootMenuIds = '';
     
    }

    console.log("SOTreeMenu selectOpenRoot changed:",changed);

    return changed;

  }

  
  function onNodeSelect(event: React.SyntheticEvent, nodeId: any) {

    console.log('SOTreeMenu onNodeSelect nodeIds', nodeId);
    console.log('SOTreeMenu onNodeSelect event', event);

    var vm = props.state.globalUIState;
    var changed: boolean = false;

    if (nodeId == rootMenuId) {

      changed = selectOpenRoot(nodeId);
 

    }
    else {
      vm.selectedConstructionMenuIds = [ nodeId ];
      App.RouteManager.routeToPath(nodeId);
      if (vm.isMobile) {
        vm.isMobileMenuOpen = false;
      }

    }
    // console.log('SOTreeMenu.onNodeSelect selectedConstructionRootMenuIds',vm.selectedConstructionRootMenuIds);
    // console.log('SOTreeMenu.onNodeSelect selectedConstructionMenuIds',vm.selectedConstructionMenuIds);
 
    //   App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);

    if (changed) {
      console.log("SOTreeMenu onNodeSelect changed:", changed);
      App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);
    }

  }
 

  function onClick(navigateTo: string) {
    console.log('SOTreeMenu.onClick navigateTo:', navigateTo);

    var vm = props.state.globalUIState;

    App.RouteManager.routeToPath(navigateTo);
    if (vm.isMobile) {
      vm.isMobileMenuOpen = false;
    }

   // App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);

  }

  console.log("SOTreeMenu RENDER..................");

  console.log("SOTreeMenu checkSetSelected selectedConstructionRootMenuIds 3:", vm.selectedConstructionRootMenuIds);


  return (
    <TreeView
      //  className={classes.root}
      //  defaultExpanded={['3']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      //   defaultEndIcon={<div style={{ width: 24 }} />}
      onNodeSelect={onNodeSelect}

      // defaultExpanded={vm.selectedConstructionRootMenuIds}
      expanded={[ vm.selectedConstructionRootMenuIds ]}
      selected={vm.selectedConstructionMenuIds}
    >

      <LIB.StyledTreeItem nodeId="/Construction" labelText="Construction" labelIcon={Icons.Home} iconColor="#ff2a2a" isroot={'true'} >

        {menuItems}

      </LIB.StyledTreeItem>

    </TreeView>
  );
}
