
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class ConstructionVm extends LIB.BaseVmModel {

    public selectedTabIndex: number;
    public selectedTab: string;

    public isConstructionPermitsAddOpen: boolean = false;
    public isConstructionPermitsEditOpen: boolean = false;
    public isConstructionPermitsDeleteOpen: boolean = false;
    //public apiConstructionPermitsAddEditErrorMessage: string;
 
   
    public isJobsAddOpen: boolean = false;
    public isJobsEditOpen: boolean = false;
    public isJobsDeleteOpen: boolean = false;

    public selectedJob: Api.Job;
    public selectedConstructionPermit: Api.ConstructionPermit;
    public selectedConstructionPermitId: number=-1;

    public selectedJobOriginal: Api.Job;
    public selectedConstructionPermitOriginal: Api.ConstructionPermit;




    constructor() {

        super();
    }

    public setSelectedConstructionPermit(item: Api.ConstructionPermit | undefined=undefined) {

     
        if (item === undefined){
            item = new Api.ConstructionPermit();
            item.constructionPermitId=0;
        }
         

            //console.log('setSelectedConstructionPermit', item);

        this.selectedConstructionPermit = item;
        this.selectedConstructionPermitOriginal = LIB.ObjectHelper.clone(this.selectedConstructionPermit);

    }

    public setSelectedJob(item: Api.Job | undefined=undefined) {

        if (item === undefined)
            item = new Api.Job();

         // console.log('setSelectedJob', item);
            
        this.selectedJob = item;
        this.selectedJobOriginal = LIB.ObjectHelper.clone(this.selectedJob);
    }



}