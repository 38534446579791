//https://v4.mui.com/components/tree-view/#tree-view

import React from 'react';
import {  Theme, createStyles } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import Label from '@mui/icons-material/Label';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SvgIconProps } from '@mui/material/SvgIcon';

import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';
import AdminIcon from '@mui/icons-material/PeopleAlt';
import { Drawer, Toolbar, List, Divider, ListItem, ListItemIcon, ListItemText, Button, Box } from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import { BrowserRouter, Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import * as App from 'AppReferences';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import { styled } from '@mui/material/styles';
import { CSSProperties } from "react";

import * as LIB from '_LIB';
import * as Api from 'app-api';

declare module 'react' {
    interface CSSProperties {
      '--tree-view-color'?: string;
      '--tree-view-bg-color'?: string;
    }
  }
  
    
   
export default function AdministrationTreeMenu() {
    //const classes = useStyles();

    return (
        <TreeView
          //  className={classes.root}
            defaultExpanded={['3']}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
          //  defaultEndIcon={<div style={{ width: 24 }} />}
        >
 
            <LIB.StyledTreeItem nodeId="3" labelText="Administration" labelIcon={Icons.Security}>
                <ListItem
                    key="AdministrationUsers"
                    component={RouterLink}
                    to="/Administration/Users"
                    
                >
                    <LIB.StyledTreeItem
                        nodeId="5"
                        labelText="Users"
                        labelIcon={Icons.People}
                   
                    />
                </ListItem>

                
                <ListItem
                    key="AdministrationEmailTemplates"
                    component={RouterLink}
                    to="/Administration/EmailTemplates"
                   
                >

 
                    <LIB.StyledTreeItem
                        nodeId="7"
                        labelText="Email Templates"
                        labelIcon={Icons.EmailOutlined}
                        color="#a250f5"
                        bgColor="#f3e8fd"
                    />
                </ListItem>

                <ListItem
                    key="AdministrationDailyEmails"
                    component={RouterLink}
                    to="/Administration/DailyEmails"
                    
                >


                    <LIB.StyledTreeItem
                        nodeId="8"
                        labelText="Daily Emails"
                        labelIcon={Icons.Email}
                        color="#3c8039"
                        bgColor="#e6f4ea"
                    />
                </ListItem>
                <ListItem
                    key="AdministrationProspects"
                    component={RouterLink}
                    to="/Administration/Prospects"
                  
                >
                    <LIB.StyledTreeItem
                        nodeId="9"
                        labelText="Prospects"
                        labelIcon={Icons.SupervisedUserCircle}
                        color="#3c8039"
                        bgColor="#e6f4ea"
                    />
                </ListItem>
               {/*  <ListItem
                    key="AdministrationConstructionPermits"
                    component={RouterLink}
                    to="/Administration/ConstructionPermits"
                  
                >
                    <LIB.StyledTreeItem
                        nodeId="10"
                        labelText="ConstructionPermits"
                        labelIcon={Icons.PeopleOutlined}
                        color="#3c8039"
                        bgColor="#e6f4ea"
                    />

                </ListItem> */}

                <ListItem
                    key="AdministrationJobs"
                    component={RouterLink}
                    to="/Administration/Jobs"
                  
                >
                    <LIB.StyledTreeItem
                        nodeId="11"
                        labelText="Jobs"
                        labelIcon={Icons.PeopleOutlined}
                        color="#3c8039"
                        bgColor="#e6f4ea"
                    />

                </ListItem>

            </LIB.StyledTreeItem>
   </TreeView>
    );
}
