import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import { AdministrationUserReducers } from './AdministrationUserReducers';
import { AdministrationDailyEmailsReducers } from './AdministrationDailyEmailsReducers';
import { AdministrationEmailTemplateReducers } from './AdministrationEmailTemplateReducers';
import { AdministrationProspectReducers } from './AdministrationProspectReducers';
import { AdministrationJobReducers } from './AdministrationJobReducers';



export class AdministrationReducers {


    constructor() {

    }

    public static getActionHandlersReducers(state: App.AdministrationState | undefined, action: App.AppAction): App.AdministrationState {
        var str = "";

        var result = new App.AdministrationState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ADMINISTRATION"))
            return state;

        if (action.type.startsWith("ADMINISTRATION_USERS"))
            return AdministrationUserReducers.getReducers(state, action);

        if (action.type.startsWith("ADMINISTRATION_DAILY_EMAILS"))
            return AdministrationDailyEmailsReducers.getReducers(state, action);

        if (action.type.startsWith("ADMINISTRATION_EMAILTEMPLATE"))
            return AdministrationEmailTemplateReducers.getReducers(state, action);

        if (action.type.startsWith("ADMINISTRATION_PROSPECTS"))
            return AdministrationProspectReducers.getReducers(state, action);
 
        if (action.type.startsWith("ADMINISTRATION_JOBS"))
            return AdministrationJobReducers.getReducers(state, action);

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        var adminUsersService = new App.AdminUsersService();


        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.ADMINISTRATION_API_ERROR:
                console.log('ADMINISTRATION_API_ERROR:');
                console.log('action.payload:', action);
                result = { ...state };
                var vm = result.vm;
                //App.AppBase.setApiError(action, appState);
                vm.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action?.payload);
                result.forceUpdateCount++;
                result.isServiceCallPending = false;
                vm.isActionInProgress = false;
                break;

            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;

    }



}