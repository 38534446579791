//import * as React from 'react';

//const jquery: any = require('jquery');

export class Common {


    constructor() {

    }

    
    public static async scrollDown(delayMS: number) {
        if(delayMS>0)
            await Common.delay(delayMS);
        console.log('scrollDown:', new Date);
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    }

    public static sleep(delayMS: number) {
        var task = Common.delay(delayMS);
        task.then(() => {
            // Do something after the sleep!
        });
        
    }

    public static delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public static getUrlParam(name: string): any {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results === null)
            return null;
        return results[ 1 ] || 0;
    }

    public static isNullOrEmpty(item: any): boolean {

        if (item === null || item === undefined || item === "" || item === '00000000-0000-0000-0000-000000000000')
            return true;

        return false;
    }


    public static contains(str: string, keyword: string): boolean {

        if (Common.isNullOrEmpty(str) || Common.isNullOrEmpty(keyword))
            return false;

        return (str.indexOf(keyword) > -1);

    }

    public static scrollToTop(behavior:ScrollBehavior='smooth') {
        window.scrollTo({ top: 0, behavior: behavior});
    }

  

}