import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class ConstructionService extends App.BaseAppService  {
 

    private attachmentService:App.AttachmentService;

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
        this.attachmentService = new App.AttachmentService();
    } 

    public getJobListData(filter:Api.JobFilterVm|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Construction/getJobListData?${urlPrams}`;
 
        this.get(apiUrl,
            App.ActionTypes.CONSTRUCTION_JOBS_LIST_DATA_RECEIVED,
            App.ActionTypes.CONSTRUCTION_API_ERROR 
            );

    } 

    public getConstructionPermitListData(filter:Api.ConstructionPermitFilterVm|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
        const apiUrl = `${App.AppBase.appApiBaseUrl}/ConstructionPermit/getConstructionPermitListData?${urlPrams}`;
 
        this.get(apiUrl,
            App.ActionTypes.CONSTRUCTION_PERMIT_LIST_DATA_RECEIVED,
            App.ActionTypes.CONSTRUCTION_API_ERROR 
            );

    }
     
    public createConstructionPermit(data:Api.ConstructionPermit) {
        console.log("ConstructionService.createConstructionPermit data: ", data);

        var formData = LIB.FormDataHelper.getFormData(data);

        console.log("ConstructionService.createConstructionPermit formData: ",formData);
  
        const apiUrl = `${App.AppBase.appApiBaseUrl}/ConstructionPermit/createConstructionPermit`;
        this.post(apiUrl, formData, 
            App.ActionTypes.CONSTRUCTION_PERMIT_ADD_SUCCESS,
            App.ActionTypes.CONSTRUCTION_PERMIT_API_ERROR
            ,'',null,null,false,true
    /*         ,'',
            (result:App.ConstructionState)=>App.AppStore.dispatch(App.ActionTypes.CONSTRUCTION_PERMIT_UPLOAD_REQUEST, 
                data?.resume) */
 
             );
 
    }

  /*   public onUploadSuccess(){
        console.log("onUploadSuccess");

        vm.isConstructionPermitsEditOpen=false;

    }

    public onUploadError(error:any){
        console.log("onUploadError error",error);
    } */

    public updateConstructionPermit(data:Api.ConstructionPermit) {
        console.log("ConstructionService.updateConstructionPermit data: ", data);

        var formData = LIB.FormDataHelper.getFormData(data);

        console.log("ConstructionService.updateConstructionPermit formData: ",formData);

       /*  if(data.resume!=undefined){
            formData.set('resumeStr',JSON.stringify(data.resume));
            formData.append('resumeFile',data.resumeFile as any);
        } */
      
        const apiUrl = `${App.AppBase.appApiBaseUrl}/ConstructionPermit/updateConstructionPermit`;
        this.post(apiUrl, formData, 
            App.ActionTypes.CONSTRUCTION_PERMIT_UPDATE_SUCCESS,
            App.ActionTypes.CONSTRUCTION_PERMIT_API_ERROR
            ,'',null,null,false,true
/*             ,'',
           ()=>App.AppStore.dispatch(App.ActionTypes.CONSTRUCTION_PERMIT_UPLOAD_REQUEST, data?.resume) */

            );
 
    }

    public deleteConstructionPermit(constructionPermitId:number) {
        console.log("ConstructionService.deleteConstructionPermit");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ConstructionPermit/deleteConstructionPermit?constructionPermitId=${constructionPermitId}`;
        this.post(apiUrl, constructionPermitId, 
            App.ActionTypes.CONSTRUCTION_PERMIT_DELETE_SUCCESS,
            App.ActionTypes.CONSTRUCTION_API_ERROR
            );
 
    }

    public createJob(data:Api.Job) {
        console.log("ConstructionService.createJob");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Construction/createJob`;
        this.post(apiUrl, data, 
            App.ActionTypes.CONSTRUCTION_JOB_ADD_SUCCESS,
            App.ActionTypes.CONSTRUCTION_API_ERROR
            );
    
    }
    public updateJob(data:Api.Job) {
        console.log("ConstructionService.updateJob");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Construction/updateJob`;
        this.post(apiUrl, data, 
            App.ActionTypes.CONSTRUCTION_JOB_UPDATE_SUCCESS,
            App.ActionTypes.CONSTRUCTION_API_ERROR
            );
    
    }

    public deleteJob(jobId:number) {
        console.log("ConstructionService.deleteJob");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Construction/deleteJob?jobId=${jobId}`;
        this.post(apiUrl, jobId, 
            App.ActionTypes.CONSTRUCTION_JOB_DELETE_SUCCESS,
            App.ActionTypes.CONSTRUCTION_API_ERROR
            );
 
    }
 
    public getConstructionPermitSubmits(){//filter:Api.ConstructionPermitFilterVm|null=null) {

        //var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
        const apiUrl = `${App.AppBase.appApiBaseUrl}/ConstructionPermit/getConstructionPermitSubmits`;//?${urlPrams}
 
        this.get(apiUrl,
            App.ActionTypes.CONSTRUCTION_GET_CANDIDATE_SUBMITS_SUCCESS,
            App.ActionTypes.CONSTRUCTION_API_ERROR 
            );

    }

     
}
