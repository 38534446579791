import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import { Alert } from '@mui/material';


interface AccountSecurityProps {
  state: App.AccountState;
  children?: React.ReactNode;

}

export class AccountSecurity extends React.Component<AccountSecurityProps> {

  public validationManager: App.ValidationManager;
  public profileManager: App.ProfileManager;
  public jobService: App.JobService;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();
    this.profileManager = new App.ProfileManager();
  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AccountSecurity componentDidMount');
    this.resetData();
 
   
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    //console.log('AccountSecurity componentDidUpdate');
 
  }

  
  public resetData() {
    console.log('AccountSecurity resetData');
    var state = this.props.state;
    var vm = state.vm;
    vm.accountPasswordChangeSm = new Api.AccountPasswordChangeSm();
   
  }


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    const item = this.props.state.vm.accountPasswordChangeSm;
    var target: any = e.target;
 
    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    //console.log('onInputChange key', key);
    //console.log('onInputChange value2', target.value);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateAccountPasswordChange(key, item);

    //console.log('onInputChange item', item);

    this.forceUpdate();
  }
  
  public onCloseClick() {

    this.resetData();
    App.RouteManager.routeToPath('/')

  }


  public onSaveClick(e: React.ChangeEvent<{}>) {
    console.log('onSaveClick');


    var state = this.props.state;
    var vm = state.vm;
    var sm = vm.accountPasswordChangeSm;

    var isValid = this.validationManager.validateAccountPasswordChange(null, sm);

    if (!isValid) {
      console.log('validateAddConstructionPermitModal NOT valid');
      this.forceUpdate();
      return;
    }

    var sm = vm.accountPasswordChangeSm;
    App.AppStore.dispatch(App.ActionTypes.ACCOUNT_PASSWORD_CHANGE_REQUEST, sm
      //,()=>{this.resetData();}
       );

    vm.isActionInProgress = true;

    this.forceUpdate();

  }

 

  public render() {

    //console.log('ProjectScopeForm render');

    var state = this.props.state;
   
    var sm = state.vm.accountPasswordChangeSm;

    //if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || LIB.Common.isNullOrEmpty(item))
    //  return null;//<LIB.Loading />;

    var theme = App.AppBase.theme;

    var formControl: CSSProperties = {
      margin: theme.spacing(2),
      //minWidth:'25rem',
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };
    var fullWidth: CSSProperties = {
      margin: theme.spacing(2),
      paddingRight: 20,
      marginLeft: 0


    };

    var formInput: CSSProperties = {

      height: 50,
      //padding:15
      // padding:'20px, 16px'
    };

    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20 }

    };

    var textFieldStyle = { paddingTop: 20, marginBottom: 10 };


    var formControlHalf = App.StyleHelper.getFormControlHalfWidthStyle();

    var countries = LIB.LocationCountryHelper.getCountries();


    return <div style={{ width: '40vw', marginLeft: 10 }}>

      

      <h2 >Security</h2>
      <div>Mange your account security from this page.</div>


      <form style={{ marginTop: 30 }} >

        <h4>CHANGE PASSWORD</h4>

        <div  >

          <Grid container spacing={2}>

            <Grid item xs={12} sm={6} >

              <TextField
                name="currentPassword"
                label="Current Password"
                type='password'
                value={sm.currentPassword || ''}
                {...App.AppBase.getInputErrorProps("currentPassword")}
                onChange={(e: any) => this.onInputChange(e)}
                ///style={fullWidth}
                fullWidth
                style={formControl}
                //variant="outlined"
                InputProps={{
                  className: 'standardFormInput30',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icons.LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />

            </Grid>

          </Grid>

          <Grid container spacing={2}>

            <Grid item xs={12} sm={6} >


              <TextField
                name="password"
                label="New Password"
                type='password'
                value={sm.password || ''}
                {...App.AppBase.getInputErrorProps("password")}
                onChange={(e: any) => this.onInputChange(e)}
                ///style={fullWidth}
                fullWidth
                style={formControl}
                //variant="outlined"
                InputProps={{
                  className: 'standardFormInput30',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icons.LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />


            </Grid>

          </Grid>

          <Grid container spacing={2}>

            <Grid item xs={12} sm={6} >

              <TextField
                name="confirmPassword"
                label="Confirm New Password"
                type='password'
                value={sm.confirmPassword || ''}
                {...App.AppBase.getInputErrorProps("confirmPassword")}
                onChange={(e: any) => this.onInputChange(e)}
                ///style={fullWidth}
                fullWidth
                style={formControl}
                //variant="outlined"
                InputProps={{
                  className: 'standardFormInput30',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icons.LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />

            </Grid>

          </Grid>





        </div>



        <div style={{ marginTop: 50 }}>
          <Button
            id="btnCancel"
            variant="contained"
            //color="default"
            onClick={() => this.onCloseClick()}
          >Close</Button>

          <Button
            id="btnSave"
            color="primary"
            variant="contained"
            style={{ marginLeft: 15 }}
            onClick={this.onSaveClick.bind(this)}

          >Change Password</Button>

        </div>

      </form>


      {this.props.children}
    </div >;


  }
};
