import * as React from 'react';
import { connect } from 'react-redux';
import {
  Table, Typography, Card, CardContent, Paper, Container, Stack, Select, MenuItem,
  TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment, InputLabel, FormControl, FormControlLabel, Checkbox
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


import { ConstructionPermitListTable } from './ConstructionPermitListTable';
import { ConstructionPermitAddEditModal } from './ConstructionPermitAddEditModal';



class ConstructionPermitList extends React.Component<App.ConstructionState, {}> {


  constructor(props: any) {
    super(props);

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('ConstructionPermitList componentDidMount');


    if (!this.props.hasConstructionPermitDataLoaded) {
      console.log('AdministrationUsers getUsersListData');

      this.onFilterClick();


    }

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('ConstructionPermitList componentDidUpdate');

  }


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.permitSearchFilter;

    var target: any = e.target;

    var name = LIB.FormHelper.setInputChangeData(e.target, searchFilter);

    if (LIB.Common.isNullOrEmpty(name))
      return;

    this.forceUpdate();

  }

  public onCreateClick() {
    console.log('onCreateClick');
    var state = this.props;
    var vm = state.vm;


    vm.setSelectedConstructionPermit();

    App.RouteManager.routeToPath(`/Construction/Permits/0/`);

    //vm.isConstructionPermitsAddOpen = true;
    this.forceUpdate();


  }


  public onDeleteConfirmClick() {
    console.log('onDeleteConfirmClick');

    var state = this.props;
    var vm = state.vm;

    var itemId = vm.selectedConstructionPermit?.constructionPermitId;

    console.log('onDeleteConfirmClick itemId', itemId);

    App.AppStore.dispatch(App.ActionTypes.CONSTRUCTION_PERMIT_DELETE_REQUEST, itemId);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }



  public onFilterClick(isSearch: boolean = false) {
    console.log('ConstructionPermitList onFilterClick');

    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.permitSearchFilter;
    searchFilter.isSearch = isSearch;
    if (isSearch)
      searchFilter.pageNum = 0;

    console.log('ConstructionPermitList onFilterClick searchFilter', searchFilter);


    App.AppStore.dispatch(App.ActionTypes.CONSTRUCTION_PERMIT_LIST_DATA_REQUEST, searchFilter);

  }


  public AutocompleteChange(name: string, value: any, selectedTypes: any) {

    console.log('EditProjectAddRequestModal onRolesChange name', name);
    console.log('EditProjectAddRequestModal onRolesChange value', value);
    console.log('EditProjectAddRequestModal onRolesChange selectedTypes', selectedTypes);

    var state = this.props;
    var vm = state.vm;
    var sm = state.permitSearchFilter;

    ///LIB.FormHelper.setAutocompleteChangeForType(name,value,selectedTypes,sm);

    this.forceUpdate();
  }


  public render() {

    console.log('ConstructionPermitList render');

    var state = this.props;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;
    var filter = state.permitSearchFilter;
    var sm = filter;

    // console.log('ConstructionPermitList hasConstructionPermitDataLoaded', state.hasConstructionPermitDataLoaded);
    // console.log('ConstructionPermitList isServiceCallPending',this.myListingsService.isServiceCallPending);
    // console.log('ConstructionPermitList vm.isActionInProgress',vm.isActionInProgress);
    // console.log('ConstructionPermitList permitSearchFilter',sm);


    var fullWidth: CSSProperties = {
      // paddingRight: 20,
      paddingLeft: 0,
      // width: '100px'
    };
    var formInput: CSSProperties = {
      height: 30,
      //  marginTop: 10,
      //paddingTop: 20,
      padding: 10,
      minWidth: 200
    };



    var createBtn: any = null;

    if (currentUser.hasRole(App.UserRoleTypeEnum.PermitsAdmin)) {
      createBtn = <Button
        id="btnCreate"
        variant="contained"
        style={{ marginBottom: 10, width: 40, padding: 5, minWidth: 20 }}
        color="primary"

        onClick={() => this.onCreateClick()}
      ><Icons.AddOutlined /></Button>
    }


    return <App.MainPage
      pageTitle={<div>Permits {createBtn}</div>}
      state={vm}
      hasDataLoaded={state.hasConstructionPermitDataLoaded}
      loading={(state.isServiceCallPending)}// && !vm.isActionInProgress)}
      errorMessage={state.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      // isChildPage={true}
      showLeftMenu={true}
    >
      <div  >

        {/*  <div style={{ textAlign: 'right', width: '100%' }} >

          {createBtn}

        </div> */}


        <div style={{
          width: '90%', display: 'flex',
          flexDirection: 'row', flexWrap: 'wrap', gap: '20px', alignItems: 'center', justifyContent: 'left', 
          marginBottom:10
        }}>


 
          <TextField
            name="keyword"
            label="Keyword"
            value={sm.keyword || ''}
            placeholder="Search Keyword"
            onChange={this.onInputChange.bind(this)}
            style={{ width: '200px' }}
            inputProps={{ style: formInput }}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                this.onFilterClick(true);
              }
            }}

          />


          <TextField
            name="zipCode"
            label="Zip Code"
            value={sm.zipCode || ''}
            placeholder="Zip Code"
            onChange={this.onInputChange.bind(this)}
            style={{ width: '100px' }}
            inputProps={{ style: formInput }}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                this.onFilterClick(true);
              }
            }}

          />
          <FormControl sx={{ width: 200, paddingTop: 1 }}>

            <InputLabel style={{ marginTop: 10 }}>Permit Status</InputLabel>
            <Select
              value={sm.permitStatus || ''}
              variant='standard'
              onChange={(e: any) => { sm.permitStatus = e.target.value; this.forceUpdate() }}
              // input={<OutlinedInput label="Name" />}
              //  MenuProps={MenuProps}
              style={{ marginTop: 10, paddingTop: 15 }}


            >
              {state.permitStatuses?.map((val) => (
                <MenuItem
                  key={val}
                  value={val}

                >
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: 200, paddingTop: 1 }}>

            <InputLabel style={{ marginTop: 10 }}>Status</InputLabel>
            <Select
              value={sm.statusTypeId || ''}
              variant='standard'
              onChange={(e: any) => { sm.statusTypeId = e.target.value; this.forceUpdate() }}

              style={{ marginTop: 10, paddingTop: 15 }}



            >
              {state.permitStatusTypes?.map((item) => (
                <MenuItem
                  key={item.name}
                  value={item.lookupTypeId}

                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>


          <FormControl sx={{ width: 200, paddingTop: 1 }}>

            <InputLabel style={{ marginTop: 10 }}>Priority</InputLabel>
            <Select
              value={sm.priorityTypeId || ''}
              variant='standard'
              onChange={(e: any) => { sm.priorityTypeId = e.target.value; this.forceUpdate() }}

              style={{ marginTop: 10, paddingTop: 15 }}



            >
              {state.permitPriorityTypes?.map((item) => (
                <MenuItem
                  key={item.name}
                  value={item.lookupTypeId}

                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

 
</div>

                <div style={{display:'flex', gap:5}}>

               
          <FormControlLabel
            control={
              <Checkbox
                name="onlyArmo"
                color="primary"
                checked={sm.onlyArmo}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    this.onFilterClick(true);
                  }
                }}

              />
            }
            style={{ marginLeft: 20 }}
            labelPlacement="start"
            label="Armo"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="onlyADUOrAddition"
                color="primary"
                checked={Boolean(sm.onlyADUOrAddition)}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    this.onFilterClick(true);
                  }
                }}

              />
            }
            style={{ marginLeft: 20 }}
            labelPlacement="start"
            label=" ADU/Addition"
          />


          <FormControlLabel
            control={
              <Checkbox
                name="hasOwner"
                color="primary"
                checked={sm.hasOwner}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    this.onFilterClick(true);
                  }
                }}

              />
            }
            style={{ marginLeft: 20 }}
            labelPlacement="start"
            label="Has Owner"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="hasInspectionHistory"
                color="primary"
                checked={sm.hasInspectionHistory}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    this.onFilterClick(true);
                  }
                }}

              />
            }
            style={{ marginLeft: 20 }}
            labelPlacement="start"
            label="Had Inspections"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="hasPendingInspections"
                color="primary"
                checked={sm.hasPendingInspections}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    this.onFilterClick(true);
                  }
                }}

              />
            }
            style={{ marginLeft: 20 }}
            labelPlacement="start"
            label="Pending Inspections"
          />


          <FormControlLabel
            control={
              <Checkbox
                name="hasFinalInspections"
                color="primary"
                checked={sm.hasFinalInspections}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    this.onFilterClick(true);
                  }
                }}

              />
            }
            style={{ marginLeft: 20 }}
            labelPlacement="start"
            label="Had Final Inspections"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="hasMainFinalInspection"
                color="primary"
                checked={sm.hasMainFinalInspection}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    this.onFilterClick(true);
                  }
                }}

              />
            }
            style={{ marginLeft: 20 }}
            labelPlacement="start"
            label="Had Main Final"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="onlyOwnerBuilders"
                color="primary"
                checked={sm.onlyOwnerBuilders}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    this.onFilterClick(true);
                  }
                }}

              />
            }
            style={{ marginLeft: 20 }}
            labelPlacement="start"
            label="Owner Builders"
          />
           


</div>
   
<div style={{ marginTop: 10, display: 'flex', gap: 10, alignItems: 'center' }}>
                  <span>Count: {state.permitsCount}</span>

<Button
  id="btnSearch"
  variant="contained"
  color="primary"
  onClick={() => this.onFilterClick(true)}
  onKeyPress={(e: any) => {
    if (e.key === 'Enter') {
      this.onFilterClick(true);
    }
  }}
  disabled={state.isServiceCallPending}
  style={{ height: 40 }}

>Search</Button>

</div>

    
        <ConstructionPermitListTable state={state} onChange={() => this.forceUpdate()} />



        <ConstructionPermitAddEditModal state={state} onChange={() => this.forceUpdate()} />


      </div>



      <LIB.ConfirmModal
        visible={vm.isConstructionPermitsDeleteOpen}
        onCancelClick={() => { vm.isConstructionPermitsDeleteOpen = false; this.forceUpdate(); }}
        onConfirmClick={() => this.onDeleteConfirmClick()}
        title={`Confirm Delete: ${vm.selectedConstructionPermit?.permitNumber}`}
        content={`Are you sure you want to delete "${vm.selectedConstructionPermit?.permitNumber}" permit?`}
        confirmButtonLabel="Delete"
        disabled={vm.isActionInProgress}
      />


    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.constructionState })
)(ConstructionPermitList as any);

