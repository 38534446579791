import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, Stack, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import { useParams, useLocation } from 'react-router';


import { ConstructionPermitDetailForm } from './ConstructionPermitDetailForm';



//class ConstructionPermitDetail extends React.Component<App.ConstructionState, {}> {
  const ConstructionPermitDetail: React.FC<App.ConstructionState> = (props) => {


  var validationManager: App.ValidationManager = new App.ValidationManager();
  var params = useParams();
  var location = useLocation();

  
  React.useEffect(() => {
    console.log('ConstructionPermitDetailForm componentDidMount');
    var vm = props.vm;

    if (props.hasConstructionPermitDataLoaded)
      return;

    console.log('ConstructionPermitDetailForm componentDidMount id', params.id);

    var id = Number(params.id);
    vm.selectedConstructionPermitId = -1;

    console.log('ConstructionPermitDetailForm componentDidMount isNaN', isNaN(id));

    if (isNaN(id))
      return;

    vm.selectedConstructionPermitId = id;
    var searchFilter = new Api.ConstructionPermitFilterVm(); //state.permitSearchFilter;
    searchFilter.isSearch = true;
  
    console.log('onFilterClick id',id);
 
    searchFilter.constructionPermitId = id;
    App.AppStore.dispatch(App.ActionTypes.CONSTRUCTION_PERMIT_LIST_DATA_REQUEST, searchFilter);

  }, []);//Run only Once
 
 

  function onDeleteConfirmClick() {
    console.log('onDeleteConfirmClick');

    var state = props;
    var vm = state.vm;

    var itemId = vm.selectedConstructionPermit?.constructionPermitId;

    console.log('onDeleteConfirmClick itemId', itemId);

    App.AppStore.dispatch(App.ActionTypes.CONSTRUCTION_PERMIT_DELETE_REQUEST, itemId);

    vm.isActionInProgress = true;

    //this.forceUpdate();

  }

  function forceUpdate(){

  }

  

  function render() {

    console.log('ConstructionPermitDetail render');

    var state = props;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;
    var filter = state.permitSearchFilter;

    // console.log('ConstructionPermitDetail hasConstructionPermitDataLoaded', state.hasConstructionPermitDataLoaded);
    // console.log('ConstructionPermitDetail isServiceCallPending',this.myListingsService.isServiceCallPending);
    // console.log('ConstructionPermitDetail vm.isActionInProgress',vm.isActionInProgress);

 
    if (!currentUser.hasRole(App.UserRoleTypeEnum.PermitsAdmin)) {
      return null;
    }


    return <App.MainPage
      pageTitle=""
      state={vm}
      hasDataLoaded={state.hasConstructionPermitDataLoaded}
      loading={(state.isServiceCallPending)}// && !vm.isActionInProgress)}
      errorMessage={state.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      // isChildPage={true}
      showLeftMenu={true}
    >
      <div>
 
        <ConstructionPermitDetailForm state={state} onChange={() => forceUpdate()} />


      </div>



      <LIB.ConfirmModal
        visible={vm.isConstructionPermitsDeleteOpen}
        onCancelClick={() => { vm.isConstructionPermitsDeleteOpen = false;   }}
        onConfirmClick={() => onDeleteConfirmClick()}
        title={`Confirm Delete: ${vm.selectedConstructionPermit?.permitNumber}`}
        content={`Are you sure you want to delete "${vm.selectedConstructionPermit?.permitNumber}" permit?`}
        confirmButtonLabel="Delete"
        disabled={vm.isActionInProgress}
      />


    </App.MainPage>

}

return render();
}
export default connect(
  (state: App.AppState) => ({ ...state.constructionState })
)(ConstructionPermitDetail as any);

