import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class ConstructionState  extends LIB.BaseStateModel {
 
  
    public vm:App.ConstructionVm;
 
    public jobs:Api.Job[];

    public jobStatusTypes:Api.JobStatusType[];
    public jobTypes:Api.JobType[];
  
    public jobSearchFilter:Api.JobFilterVm = new Api.JobFilterVm();

    public hasJobsDataLoaded:boolean=false;


    public jobSm:Api.Job = new Api.Job();
    public permitSm:Api.ConstructionPermit = new Api.ConstructionPermit();

    public hasResumeRequestsLoaded:boolean=false;
    public hasConstructionPermitSubmitsLoaded:boolean=false;

    public permits:Api.ConstructionPermit[]=[];
    public permitStatuses:string[];

    public isConstructionPermitCreateUpdatePending: boolean = false;
    public permitSearchFilter:Api.ConstructionPermitFilterVm = new Api.ConstructionPermitFilterVm();
    public hasConstructionPermitDataLoaded:boolean=false;
    public permitsCount:number=0;
 
    public permitStatusTypes:Api.LookupType[]=[];
    public permitPriorityTypes:Api.LookupType[]=[];

    constructor() { 
        
        super();
       
        this.vm = new App.ConstructionVm();

      
    }
     


}