import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, TextareaAutosize, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Link, Stack, MenuItem, FormControl, InputLabel, Select
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
//import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface ConstructionPermitDetailFormProps {
  state: App.ConstructionState;
  onChange?: Function;
}


export class ConstructionPermitDetailForm extends React.Component<ConstructionPermitDetailFormProps> {

  // todo public designSetDataService: App.ConstructionPermitDataService;
  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    // todo this.designSetDataService = new App.ConstructionPermitDataService();
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    // console.log('ConstructionPermitDetailForm componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('ConstructionPermitDetailForm componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onAddOrUpdateClick(saveAndClose: boolean) {
    console.log('onAddOrUpdateClick');


    var state = this.props.state;
    var vm = state.vm;
    var sm = state.permitSm;
    var item = vm.selectedConstructionPermit;
    var items = state.permits;

    //todo item.isPublished = isPublished;

    var isValid = this.validationManager.validateConstructionPermitCreateUpdate(null, item);

    if (!isValid) {
      console.log('validateAddConstructionPermitModal NOT valid, ErrorMessage:', App.AppBase.lastErrorMessage);
      this.forceUpdate();
      return;
    }

    if (vm.selectedConstructionPermit.constructionPermitId!! > 0)
      App.AppStore.dispatch(App.ActionTypes.CONSTRUCTION_PERMIT_UPDATE_REQUEST, item);
    else
      App.AppStore.dispatch(App.ActionTypes.CONSTRUCTION_PERMIT_ADD_REQUEST, item);

    vm.isActionInProgress = true;

    if(saveAndClose)
      this.onCloseClick();

  }

  public onCloseClick() {
    console.log('onCloseClick');
    var state = this.props.state;
    var vm = state.vm;
    var sm = state.permitSm;



    //reset edits
    App.ConstructionPermitManager.update(vm.selectedConstructionPermitOriginal, state.permits);

    //vm.isConstructionPermitsAddOpen = false;
    //vm.isConstructionPermitsEditOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

    //  if(vm.selectedConstructionPermitId>-1)
    //      App.RouteManager.routeToPath(`/Construction/Permits`);
    //   else 
    window.history.go(-1);


    // console.log('onCloseClick onChange');

    if (this.props.onChange !== undefined) {
      //@ts-ignore
      this.props.onChange();
    }

    // console.log('onCloseClick end');

  }

  public onAutocompleteChange(name: string, value: any, selectedTypes: any, valueProperty: any = '') {
    console.log('onAutocompleteChange name', name);
    console.log('onAutocompleteChange value', value);
    console.log('onAutocompleteChange selectedTypes', selectedTypes);

    var state = this.props.state;
    var vm = state.vm;

    var sm = state.permitSm;

    var item = vm.selectedConstructionPermit;

    var key = LIB.FormHelper.setAutocompleteChangeForIdAndType(name, item, selectedTypes, valueProperty);

    console.log('onAutocompleteChange setAutocompleteChangeForIdAndType key', key);
    console.log('onAutocompleteChange selectedItem', item);


    this.validationManager.validateConstructionPermitCreateUpdate(key, item);

    this.forceUpdate();
  }


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedConstructionPermit;

    var target: any = e.target;

    var name = LIB.FormHelper.setInputChangeData(e.target, item);

    if (LIB.Common.isNullOrEmpty(name))
      return;


    this.validationManager.validateConstructionPermitCreateUpdate(name, item);


    this.forceUpdate();
  }


  public getForm() {
    //console.log('ConstructionPermitDetailForm getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedConstructionPermit;
    var currentUser = App.AppBase.currentUser;

    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };
    var textInput: CSSProperties = {
      padding: 10,
      width: '100%'
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };

    var item = vm.selectedConstructionPermit;

    if (item === undefined)
      return null;

    //  var statusTag = App.FeaturedManager.getStatusNameTag(item);

    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasConstructionPermitDataLoaded}
      loading={(state.isConstructionPermitCreateUpdatePending || vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      <form     >

        <div >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                variant='standard'
                name="permitNumber" label="Permit"
                value={item.permitNumber || ''}
                {...App.AppBase.getInputErrorProps('permitNumber')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
              />
            </Grid>
            <Grid item xs={12} sm={4}
            >
              <TextField
                name="address" label="Address"
                //placeholder="i.e Full Stack .NET/React Developer"
                value={item.address || ''}
                {...App.AppBase.getInputErrorProps('address')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

            </Grid>

            <Grid item xs={12} sm={2}
            >
              <TextField
                name="zipCode" label="Zip Code"
                //placeholder="i.e Full Stack .NET/React Developer"
                value={item.zipCode || ''}
                {...App.AppBase.getInputErrorProps('zipCode')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

            </Grid>


          </Grid>


          <div>

            <TextField
              name="workDescription" label="Work Description"
              value={item.workDescription || ''}
              {...App.AppBase.getInputErrorProps('workDescription')}
              onChange={this.onInputChange.bind(this)}
              style={fullWidth}
              //placeholder="1 or 2 main skills, i.e .Net, React"
              inputProps={{ style: formInput }}
              rows={5}
              multiline
            />


          </div>


          <div>

            <TextField
              name="addresses" label="Other Addresses"
              value={item.addresses || ''}
              {...App.AppBase.getInputErrorProps('addresses')}
              onChange={this.onInputChange.bind(this)}
              style={fullWidth}
              //placeholder="1 or 2 main skills, i.e .Net, React"
              inputProps={{ style: formInput }}
              rows={5}
              multiline
            />


          </div>



          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                variant='standard'
                name="permitStatus" label="Permit Status"
                value={item.permitStatus || ''}
                {...App.AppBase.getInputErrorProps('permitStatus')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
              />
            </Grid>
            <Grid item xs={12} sm={3}
            >
              <TextField
                name="permitIssuedDate" label="Issued Date"
                value={LIB.DateHelper.getFormattedShortDate(item.permitIssuedDate) || ''}
                {...App.AppBase.getInputErrorProps('permitIssuedDate')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

            </Grid>

            <Grid item xs={12} sm={3}
            >
              <TextField
                name="permitStatusDate" label="Status Date"
                value={LIB.DateHelper.getFormattedShortDate(item.permitStatusDate) || ''}
                {...App.AppBase.getInputErrorProps('permitIssuedDate')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

            </Grid>


          </Grid>



          <div>

          </div>





          <Stack direction={'row'}>



            <FormControlLabel
              control={

                <Switch name="isHot"
                  checked={item.hasPendingInspections}
                  onChange={this.onInputChange.bind(this)}
                />

              }
              style={{ marginLeft: 0 }}
              labelPlacement="start"
              label="Has Pending Inspections"
            />

            <FormControlLabel
              control={

                <Switch name="isPublished"
                  checked={item.hasInspectionHistory}
                  onChange={this.onInputChange.bind(this)}

                  color="primary"
                />

              }
              style={{ marginLeft: 20 }}
              labelPlacement="start"
              label="Has Inspections"
            />

            <FormControlLabel
              control={

                <span style={{ marginLeft: 10 }}> {LIB.DateHelper.getFormattedShortDate(item.dateModified)}</span>

              }
              style={{ marginLeft: 20 }}
              labelPlacement="start"
              label="Date Modified:"
            />


            <FormControlLabel
              control={

                <span style={{ marginLeft: 10 }}> {item.isOwnerCheckDone}</span>

              }
              style={{ marginLeft: 20 }}
              labelPlacement="start"
              label="Owner Check Count:"
            />


          </Stack>

          <div style={{ marginTop: 15, marginBottom: 15, display: 'grid', gridTemplateColumns:'1fr 1fr', gap: 5 }} >
            <div><b>Engineer:</b> {item.engineer}</div>
            <div><b>Architect:</b> {item.architect}</div>
            <div><b>Contractor:</b> {item.contractor}</div>

            <div><b>Building Type:</b> {item.buildingType}</div>

             <div><b>First Inspection Date:</b> {LIB.DateHelper.getFormattedShortDate(item.firstInspectionDate)}</div>
             <div><b>Last Inspection Date: </b> {LIB.DateHelper.getFormattedShortDate(item.lastInspectionDate)}</div>

            <div><b>Inspection Count:</b> {item.countInspectionHistory}</div>
            <div><b>Pending Inspections:</b> {item.hasPendingInspections}</div>

            <div><b>Final Date:</b> {LIB.DateHelper.getFormattedShortDate(item.mainFinalInspectionDate)}</div>

            <div><b>Date Modified:</b> {LIB.DateHelper.getFormattedShortDate(item.dateModified)}</div>
            <div><b>Progress Updated:</b> {LIB.DateHelper.getFormattedShortDate(item.dateProgressUpdated)}</div>

          </div>




          <FormControl sx={{ width: 200, marginTop: 2, backgroundColor: 'yellow', padding: 1 }}>

            <InputLabel style={{ marginTop: 10, marginLeft: -12 }}>Status</InputLabel>
            <Select
              value={item.statusTypeId || ''}
              variant='standard'
              onChange={(e: any) => { item.statusTypeId = e.target.value; this.forceUpdate() }}

              style={{ marginTop: 10, paddingTop: 15 }}

            >
              {state.permitStatusTypes?.map((item) => (
                <MenuItem
                  key={item.name}
                  value={item.lookupTypeId}

                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>


          <FormControl sx={{ width: 200, marginTop: 2, marginLeft:5, backgroundColor: 'yellow', padding: 1 }}>

            <InputLabel style={{ marginTop: 10, marginLeft: -12 }}>Priority</InputLabel>
            <Select
              value={item.priorityTypeId || ''}
              variant='standard'
              onChange={(e: any) => { item.priorityTypeId = e.target.value; this.forceUpdate() }}

              style={{ marginTop: 10, paddingTop: 15 }}

            >
              {state.permitPriorityTypes?.map((item) => (
                <MenuItem
                  key={item.name}
                  value={item.lookupTypeId}

                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>


          <div style={{ marginTop: 50, textAlign: 'right' }}>
          {this.getButtons()}
        </div>

          <h2 className='permitDetailGroupHeader'>CONTACTS</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                variant='standard'
                name="ownerName" label="Owner Name"
                value={item.ownerName || ''}
                {...App.AppBase.getInputErrorProps('ownerName')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
                disabled={!currentUser.isAdmin}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'baseline' }}
            >
              <TextField
                name="ownerPhone" label="Owner Phone"
                value={item.ownerPhone || ''}
                {...App.AppBase.getInputErrorProps('ownerPhone')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

              <Link href={LIB.StringHelper.getPhoneTelLink(item.ownerPhone)}>
                <Icons.Phone />
              </Link>

            </Grid>

          </Grid>


          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                variant='standard'
                name="contact1" label="Contact 1"
                value={item.contact1 || ''}
                {...App.AppBase.getInputErrorProps('contact1')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'baseline' }}
            >
              <TextField
                name="contact1Phone" label="Contact 1 Phone"
                value={item.contact1Phone || ''}
                {...App.AppBase.getInputErrorProps('contact1Phone')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

              <Link href={LIB.StringHelper.getPhoneTelLink(item.contact1Phone)}>
                <Icons.Phone />
              </Link>

            </Grid>

          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                variant='standard'
                name="contact2" label="Contact 2"
                value={item.contact2 || ''}
                {...App.AppBase.getInputErrorProps('contact2')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'baseline' }}
            >
              <TextField
                name="contact2Phone" label="Contact 2 Phone"
                value={item.contact2Phone || ''}
                {...App.AppBase.getInputErrorProps('contact2Phone')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

              <Link href={LIB.StringHelper.getPhoneTelLink(item.contact2Phone)}>
                <Icons.Phone />
              </Link>

            </Grid>

          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                variant='standard'
                name="contact3" label="Contact 3"
                value={item.contact3 || ''}
                {...App.AppBase.getInputErrorProps('contact3')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'baseline' }}
            >
              <TextField
                name="contact3Phone" label="Contact 3 Phone"
                value={item.contact3Phone || ''}
                {...App.AppBase.getInputErrorProps('contact3Phone')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

              <Link href={LIB.StringHelper.getPhoneTelLink(item.contact3Phone)}>
                <Icons.Phone />
              </Link>

            </Grid>

          </Grid>


          <h2 className='permitDetailGroupHeader'>CALL NOTES</h2>



          <div>



            <TextField
              name="notes" label="Notes"
              value={item.notes || ''}
              {...App.AppBase.getInputErrorProps('notes')}
              onChange={this.onInputChange.bind(this)}
              style={fullWidth}
              //placeholder="1 or 2 main skills, i.e .Net, React"
              inputProps={{ style: formInput }}
              rows={10}
              multiline
            />

          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                variant='standard'
                name="permitOwner" label="Permit Owner - Verify"
                value={item.permitOwner || ''}
                {...App.AppBase.getInputErrorProps('permitOwner')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
                helperText="Person who applied for the permits"
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'baseline' }}
            >
              <TextField
                name="permitOwnerPhone" label="Permit Owner Phone - Verify"
                value={item.permitOwnerPhone || ''}
                {...App.AppBase.getInputErrorProps('permitOwnerPhone')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

              <Link href={LIB.StringHelper.getPhoneTelLink(item.permitOwnerPhone)}>
                <Icons.Phone />
              </Link>

            </Grid>

          </Grid>


          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                variant='standard'
                name="contractorName" label="Contractor Name - Verify"
                value={item.contractorName || ''}
                {...App.AppBase.getInputErrorProps('contractorName')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
                helperText="Main Contractor - person who's doing the work"
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'baseline' }}
            >
              <TextField
                name="contractorPhone" label="Contractor Phone - Verify"
                value={item.contractorPhone || ''}
                {...App.AppBase.getInputErrorProps('contractorPhone')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

              <Link href={LIB.StringHelper.getPhoneTelLink(item.contractorPhone)}>
                <Icons.Phone />
              </Link>

            </Grid>

          </Grid>
          <div><b>Contractor/Company Registered:</b> {item.contractor}</div>

          <FormControl sx={{ width: 200, marginTop: 2, backgroundColor: 'yellow', padding: 1 }}>

            <InputLabel style={{ marginTop: 10, marginLeft: -12 }}>Status</InputLabel>
            <Select
              value={item.statusTypeId || ''}
              variant='standard'
              onChange={(e: any) => { item.statusTypeId = e.target.value; this.forceUpdate() }}

              style={{ marginTop: 10, paddingTop: 15 }}

            >
              {state.permitStatusTypes?.map((item) => (
                <MenuItem
                  key={item.name}
                  value={item.lookupTypeId}

                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/*  {this.getResumeUploadElement()}
 */}

        </div>


        <div style={{ marginTop: 50, textAlign: 'right' }}>
          {this.getButtons()}
        </div>


      </form>

    </App.MainPage>;


  }

  public getButtons() {


    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedConstructionPermit;


    return <span >

      <Button
        variant="contained"
        color="inherit"
        style={{ margin: 10, marginLeft: 0 }}
        onClick={this.onCloseClick.bind(this)}
        disabled={vm.isActionInProgress}
      >Close</Button>


      <Button
        color="primary"
        variant="contained"
        style={{ margin: 10 }}
        //onClick={() => this.onAddOrUpdateClick(true)}
        onClick={() => this.onAddOrUpdateClick(false)}
        disabled={vm.isActionInProgress}
      >Save</Button>

    <Button
        color="primary"
        variant="contained"
        style={{ margin: 10 }}
        //onClick={() => this.onAddOrUpdateClick(true)}
        onClick={() => {this.onAddOrUpdateClick(true); } }
        disabled={vm.isActionInProgress}
      >Save and Close</Button>

    </span>

  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    //console.log('ConstructionPermitDetailForm render');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedConstructionPermit;

    if (!item)
      return null;

    var title = "Add Permit";
    var saveBtnTitle = "Save Draft"; //"Add";
    if (item.constructionPermitId!! > 0) {
      title = `Edit Permit: ${item.permitNumber} (${item.constructionPermitId})`;
      saveBtnTitle = "Save Draft";
    }

    var statusTag = App.FeaturedManager.getStatusNameTag(item);

    return <div style={{ width: '50vw', marginLeft: 10 }}>






      <h2 >{title} {statusTag}



      </h2>
      <div style={{ marginBottom: 20 }}>
        {this.getButtons()}

      </div>

      {this.getForm()}


    </div>;

  }
} 