import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import { ConstructionPermitReducers } from './ConstructionPermitReducers';
import { ConstructionJobsReducers } from './ConstructionJobsReducers';


export class ConstructionReducers {


    constructor() {

    }

    public static getActionHandlersReducers(state: App.ConstructionState | undefined, action: App.AppAction): App.ConstructionState {
        var str = "";

        var result = new App.ConstructionState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("CONSTRUCTION"))
            return state;

        if (action.type.startsWith("CONSTRUCTION_PERMIT"))
            return ConstructionPermitReducers.getReducers(state, action);
        else if (action.type.startsWith("CONSTRUCTION_JOB"))
            return ConstructionJobsReducers.getReducers(state, action);

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        var myListingsService = new App.ConstructionService();


        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.CONSTRUCTION_API_ERROR: {
                console.log('CONSTRUCTION_API_ERROR:');
                console.log('action.payload:', action);
                result = { ...state };
                var vm = result.vm;
                //App.AppBase.setApiError(action, appState);
                vm.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action?.payload);
                result.forceUpdateCount++;
                result.isServiceCallPending = false;
                vm.isActionInProgress = false;
                break;
            }


            case App.ActionTypes.CONSTRUCTION_GET_RESUME_REQUESTS_REQUEST: {
                console.log('CONSTRUCTION_GET_RESUME_REQUESTS_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;

               
                // result.isConstructionPermitCreateUpdatePending = true;
                result.isServiceCallPending=true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.CONSTRUCTION_GET_RESUME_REQUESTS_SUCCESS: {
                console.log('CONSTRUCTION_GET_RESUME_REQUESTS_SUCCESS:',action.payload);
                result = { ...state };
                
                //result.resumeRequests = action.payload.resumeRequests;

                result.hasResumeRequestsLoaded = true;
                result.isServiceCallPending=false;
                break;
            }

            
            case App.ActionTypes.CONSTRUCTION_GET_CANDIDATE_SUBMITS_REQUEST: {
                console.log('CONSTRUCTION_GET_CANDIDATE_SUBMITS_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;

                myListingsService.getConstructionPermitSubmits();

                // result.isConstructionPermitCreateUpdatePending = true;
                result.isServiceCallPending=true;
                result.forceUpdateCount++;
                break;
            }
            
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;

    }



}