import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdministrationEmailTemplateReducers {


    constructor() {

    }

    public static getReducers(state: App.AdministrationState | undefined, action: App.AppAction): App.AdministrationState {
        var str = "";

        var result = new App.AdministrationState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ADMINISTRATION_EMAILTEMPLATE"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

       var emailTemplateService = new App.EmailTemplateService();


        // const action = incomingAction as KnownAction;
        switch (action.type) {
            case App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_RECEIVED: {
                console.log('ADMINISTRATION_EMAILTEMPLATE_LIST_PAGE_DATA_RECEIVED:');
                result = { ...state };
                if (LIB.Common.isNullOrEmpty(result.emailTemplateSearchFilter?.keyword)) {
                    result.emailTemplatesFullList = action.payload.emailTemplates;
                }
                result.emailTemplates = action.payload.emailTemplates;

                result.isServiceCallPending = false;
               result.hasEmailTemplateDataLoaded = true;
                console.log("result" , result);
                break;
            }
            case App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_RESET: {
                console.log('ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_RESET:');
                result = { ...state };
                result.emailTemplates = result.emailTemplatesFullList;
                result.emailTemplateSearchFilter.keyword = '';
               // result.isServiceCallPending = false;
             //  result.hasEmailTemplateDataLoaded = true;
                console.log("result" , result);
                break;
            }
            
            case App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_UPDATE_REQUEST: {
                console.log('ADMINISTRATION_EMAILTEMPLATES_UPDATE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                emailTemplateService.update(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_UPDATE_SUCCESS: {
                console.log('ADMINISTRATION_EMAILTEMPLATES_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = {... action.payload };
               
                App.AdministrationManager.updateEmailTemplate(item,result.emailTemplates);
                
                vm.apiErrorMessage=''; //to clear any api error msg
                vm.isEmailTemplateEditOpen=false;
                vm.isEmailTemplateTableOpen=true;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                vm.setSelectedEmailTemplate(item);
                  
                result.forceUpdateCount++;
                break;
            }
              
            case App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_ADD_REQUEST: {
                console.log('ADMINISTRATION_EMAILTEMPLATES_ADD_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                emailTemplateService.create(data,
                    (() => App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_REQUEST)));
                result.isServiceCallPending = true;
                //result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_ADD_SUCCESS: {
                console.log('ADMINISTRATION_EMAILTEMPLATES_ADD_SUCCESS:', action.payload);

                result = { ...state };
                var vm = result.vm; 
                var item = {... action.payload };
                              //  var item = vm.;

                console.log("item pushed: ", (item));
               // console.log(item.constructor.name); 

                result.emailTemplates.splice(0, 0, item); //push at the beginning
               // result.permits.push(item);
               vm.apiErrorMessage='';
                vm.isEmailTemplateCreateOpen=false;
                vm.isEmailTemplateTableOpen=true;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;

             //   vm.setSelectedUser();
                console.log("vm.setSelectedUser();:, ", vm.selectedUser);
                //clear form
              result.adminCreateEmailTemplateSm = new Api.AdminCreateEmailTemplateSm();
              result.adminCreateEmailTemplateSm.isActive = true;

               result.forceUpdateCount++;
                break;
            }  
            case App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_DELETE_REQUEST: {
                console.log('ADMINISTRATION_EMAILTEMPLATE_DELETE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var itemId = action.payload;

                emailTemplateService.delete(itemId);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }   

            case App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_DELETE_SUCCESS: {
                console.log('ADMINISTRATION_EMAILTEMPLATES_DELETE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

                var itemId:any = vm.selectedEmailTemplate?.emailTemplateId;
                var success = action.payload;
 
                if(success)
                    App.AdministrationManager.deleteEmailTemplateById(itemId,result.emailTemplates);
                
                vm.isEmailTemplateDeleteOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                vm.setSelectedEmailTemplate();
                  
                result.forceUpdateCount++;
                break;
            }   
            
            case App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_REQUEST: {
                console.log('ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var searchFilter = action.payload;
                emailTemplateService.getEmailTemplatesListData(searchFilter);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }

           

            
            
            default:
                result = state;
                //return result;
                break;
        }
        console.log('ADMINISTRATION_EMAILTEMPLATE_DATA_RECEIVED result2:',result);
      //  console.log('administrationuser_DATA_RECEIVED action:',action);


        if (action.postActionCallBack){
            console.log("postactioncallback");
                action.postActionCallBack(result);
            }
        return result;
    }



}