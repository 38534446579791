import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


import { AdministrationEmailTemplatesTable } from './AdministrationEmailTemplatesTable';
import { AdministrationEmailTemplatesCreate } from './AdministrationEmailTemplatesCreate';
import { AdministrationEmailTemplatesEdit } from './AdministrationEmailTemplatesEdit';



class AdministrationEmailTemplates extends React.Component<App.AdministrationState, {}> {

  public emailTemplateService: App.EmailTemplateService;

  constructor(props: any) {
    super(props);
    this.emailTemplateService = new App.EmailTemplateService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationEmailTemplates componentDidMount');

    if (!this.props.hasEmailTemplateDataLoaded) { //hasEmailTemplateDataLoaded
      console.log('AdministrationEmailTemplates getUsersListData');
       //      App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_REQUEST, null);
//change this
      this.emailTemplateService.getEmailTemplatesListData(null,
        (result: any) => {
          console.log('getEmailTemplatesListData success...');
        });

      this.forceUpdate();
      console.log('getEmailTemplatesListData forceUpdate...');

    }


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationEmailTemplates componentDidUpdate');


  }

  /* public onCreateClick() {
    console.log('onCreateClick');
    var state = this.props;
    var vm = state.vm;

    vm.setSelectedUserCreate(); //emailtemplate

    vm.isEmailTemplateCreateOpen = true;
    vm.isEmailTemplateTableOpen = false;
    this.forceUpdate();


  } */
/* 
  public onInputChange(newVal: any) {
    console.log('onInputChange:' + newVal);


  }


  public getTopRow() {

    var fullWidth: CSSProperties = {
      // paddingRight: 20,
      paddingLeft: 0,
      //  marginBottom: 10,
      //  padding: 10,

      width: '30vw'
    };
    var formInput: CSSProperties = {
      height: 50,
      //  marginTop: 10,
      //paddingTop: 20,
      padding: 10,
    };

    return <Grid container spacing={2} style={{ marginBottom: 10 }}>
      <Grid item xs={12} sm={6} >

        <TextField
          name="keyword"
          //label="Search"
          //value={item.location || ''}
          placeholder='Search'
          {...App.AppBase.getInputErrorProps('keyword')}
          onChange={this.onInputChange.bind(this)}
          style={fullWidth}
          inputProps={{ style: formInput }}
          //variant="outlined"

          InputProps={{
            // className: 'standardFormInput30',
            startAdornment: (
              <InputAdornment position="start">
                <Icons.Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  id="btnCreate"
                  variant="contained"
                  //style={{padding:10}}
                  color="primary"

                //  onClick={this.onCreateClick.bind(this)}
                >Search</Button>
              </InputAdornment>
            ),
          }}

        />

      </Grid>

      <Grid item xs={12} sm={4} >

      </Grid>

    </Grid>

  } */

  /* public onSearchClick() {
    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.emailTemplateSearchFilter;

    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_REQUEST, searchFilter);

  } */

  public onDeleteConfirmClick() {
    console.log('onDeleteConfirmClick');

    var state = this.props;
    var vm = state.vm;

    var itemId = vm.selectedEmailTemplate?.emailTemplateId;

    console.log('onDeleteConfirmClick itemId', itemId);

    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_DELETE_REQUEST, itemId);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }

  public render() {

    console.log('AdministrationEmailTemplates render');

    var state = this.props;
    var vm = state.vm;
   // var searchFilter = state.emailTemplateSearchFilter; 

    console.log('AdministrationEmailTemplatesList hasDataLoaded', state.hasDataLoaded);
    console.log('AdministrationEmailTemplatesList isServiceCallPending', this.emailTemplateService.isServiceCallPending);
    console.log('AdministrationEmailTemplatesList vm.isActionInProgress', vm.isActionInProgress);

    var keyword = '';


    /* var createBtn = <Button
      id="btnCreate"
      variant="contained"
      style={{ marginBottom: 10 }}
      color="primary"
      onClick={() => this.onCreateClick()}
    >Create(not working yet)</Button> */

    return <App.MainPage
      pageTitle=""
      state={vm}
      hasDataLoaded={state.hasEmailTemplateDataLoaded}
      loading={(this.emailTemplateService.isServiceCallPending && !vm.isActionInProgress)}
      alignError='left'
      isChildPage={true}
    >
      <div>

       

        <AdministrationEmailTemplatesTable state={state} onChange={() => this.forceUpdate()} />

        <AdministrationEmailTemplatesCreate state={state} onChange={() => this.forceUpdate()} />

        <AdministrationEmailTemplatesEdit state={state} onChange={() => this.forceUpdate()} />



      </div>

      <LIB.ConfirmModal
        visible={vm.isEmailTemplateDeleteOpen}
        onCancelClick={() => { vm.isEmailTemplateDeleteOpen = false; this.forceUpdate(); vm.apiErrorMessage = '' }}
        onConfirmClick={() => this.onDeleteConfirmClick()}
        title={`Confirm Delete: (ID: ${vm.selectedEmailTemplate?.emailTemplateId}) ${vm.selectedEmailTemplate?.name} `}
        content={`Are you sure you want to delete email template "${vm.selectedEmailTemplate?.name}" ?`}
        confirmButtonLabel="Delete"
        disabled={vm.isActionInProgress}
      />

    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.administrationState })
)(AdministrationEmailTemplates as any);

