import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
///import { NavLink } from 'reactstrap';
import { Drawer, Toolbar, List, Divider, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material/';
import { BrowserRouter, Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';
import { CSSProperties } from "react";

import * as LIB from '_LIB';
//import { RouterState } from 'connected-react-router';

import './LeftMenu.css';
import Administration from 'Pages/Admin/Administration';
import AdministrationTreeMenu from './AdministrationTreeMenu';
import { SOTreeMenu } from './SOTreeMenu';
 



//import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const drawerWidth = 240;
/* 
interface LeftMenuProps {
 
    selectedItem: string;

}
 */
//class LeftMenu extends React.Component<App.GlobalState, {}> {
export const LeftMenu: React.FC<App.GlobalState> = (props) => {


    var selectedTab: string | undefined;
    var params = useParams();
    var location = useLocation();
    var locationState = location.state;


  /*   React.useEffect(() => {
        console.log("LeftMenu useEffect update");

    }); */

    var vm = props.globalUIState;
    console.log('LeftMenu globalUIState:',vm);

    var appPath = "";//App.AppBase.appInfo.appPath;
 
    // var selectedItem = App.AppBase.history?.location?.pathname;
    var selectedItem = App.AppBase.location?.pathname;
    var currentUser = App.AppBase.currentUser;


    function getConstructionPermitMenu(){
        let menu: any = null;

        var items: any[] = [

            <LIB.StyledTreeItem 
                nodeId="/Construction/Permits" key="/Construction/ConstructionPermits"
              // onClick={()=>onClick('/Construction/ConstructionPermits')}  
              labelText="Permits" labelIcon={Icons.People} iconColor="#ff2a2a" />
            ,
          /*   <LIB.StyledTreeItem nodeId="/Construction/Contractors" key="/Construction/Contractors"
              //onClick={()=>onClick('/Construction/Jobs')}  
              labelText="Contractors" labelIcon={Icons.Work} iconColor="#ff2a2a" />
            , */
       
          ];

        if (currentUser?.isAdmin ||
            currentUser?.hasRole(App.UserRoleTypeEnum.PermitsAdmin) ||
            currentUser?.hasRole(App.UserRoleTypeEnum.ContractorAdmin)) {
     
                menu  = <SOTreeMenu rootMenuId='/Construction' params={params} state={props} location={location} items={items} />
        }
    
        return menu;

    }
 
    var administrationMenuItem: any = null;

    /*     if (!currentUser?.isAuthenticated)
                return null;
      */

   
    if (currentUser?.isAdmin ||
        currentUser?.hasRole(App.UserRoleTypeEnum.Admin)) {

        administrationMenuItem =  <AdministrationTreeMenu />;

    }

    var ListItemStyle: CSSProperties = {
        paddingTop:4,
        paddingBottom:4, 
      };

      var variant:any = 'permanent';

      if(vm.isMobile)
      variant=undefined;

      console.log('LeftMenu render:',vm);

    return <div style={{ width: 260 }} >

        <Drawer
            className="LeftMenuDrawer"
             variant={variant}
            classes={{
                paper: "LeftMenuDrawerPaper",
            }}
            anchor="left"
            open={vm.isMobileMenuOpen}
        >
            <Toolbar />
            <div className="drawerContainer" />
            <Divider />

            <List component="nav" aria-label="main mailbox folders"
                className="LeftMenuList"
                onChange={(e: any) => console.log('Menu Change')}
                style={{marginTop:20}}
            >
  

                {getConstructionPermitMenu()}


            </List>


            <Divider />

             {administrationMenuItem}

        </Drawer>
    </div>;
}
//}
export default connect(
    (state: App.AppState) => ({ ...state.globalData })
)(LeftMenu as any);


