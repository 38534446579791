import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import * as UI from '@mui/material/';


class DailyEmails extends React.Component<App.AdministrationState, {}> {

  public adminEmailService: App.AdminEmailService;
  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.adminEmailService = new App.AdminEmailService();
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('DailyEmails componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('DailyEmails componentDidUpdate');


  }

  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props;
    var vm = state.vm;
    var item = state.sailyEmailSm;

    var target: any = e.target;

    var name = LIB.FormHelper.setInputChangeData(e.target, item);

    if (LIB.Common.isNullOrEmpty(name))
      return;


    this.validationManager.validateSendDailyEmail(name, item);


    this.forceUpdate();
  }


  public onSendHotConstructionPermitsEmailClick() {
    var state = this.props;
    var vm = state.vm;
    //var searchFilter = state.searchFilter;

    if(this.adminEmailService.isServiceCallPending)
    return;
 
    var item = state.sailyEmailSm;
 
    //validate 
    var isValid = this.validationManager.validateSendDailyEmail(null, item);

    if (!isValid) {
      console.log('validateAccountProfileEditModal NOT valid');
      this.forceUpdate();
      return;
    }

    var sm = state.sailyEmailSm 

     this.adminEmailService.sendDailyEmailHotConstructionPermits(sm);

     this.forceUpdate();

  }

  
  public onSendHotJobsEmailClick() {
    var state = this.props;
    var vm = state.vm;
    //var searchFilter = state.searchFilter;

    if(this.adminEmailService.isServiceCallPending)
    return;
 
    var item = state.sailyEmailSm;
 
    //validate 
    var isValid = this.validationManager.validateSendDailyEmail(null, item);

    if (!isValid) {
      console.log('validateAccountProfileEditModal NOT valid');
      this.forceUpdate();
      return;
    }

    var sm = state.sailyEmailSm 

     this.adminEmailService.sendDailyEmailHotJobs(sm);

     this.forceUpdate();

  }

  public getForm() {
    //console.log('MyListingsJobsAddEditModal getForm');
    var state = this.props;
    var vm = state.vm;
    var sm = state.sailyEmailSm 


    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    };

    var formInput: CSSProperties = {
       height: 50,
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var partWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 50 
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };

    
    var startIcon:any = undefined;
    

    if(this.adminEmailService.isServiceCallPending){
      startIcon= <UI.CircularProgress style={{color:'white'}} size="1rem"  />;
    }
 
    return <div  >

      <div  >

        <TextField
          name="testEmail" label="Test Email"
           value={sm.testEmail || ''}
          {...App.AppBase.getInputErrorProps('testEmail')}
          onChange={this.onInputChange.bind(this)}
         style={fullWidth}
         inputProps={{ style: formInput }}

        />
 
      </div>

      <div  >

      <Button
          id="btnCreate"
          variant="contained"
          style={{ marginTop: 10 }}
          color="primary"

          onClick={this.onSendHotConstructionPermitsEmailClick.bind(this)}
          startIcon={startIcon}
        >Send Hot ConstructionPermits Email</Button>

      
<Button
    id="btnCreate"
    variant="contained"
    style={{ marginTop: 10, marginLeft:20 }}
    color="primary"

    onClick={this.onSendHotJobsEmailClick.bind(this)}
    startIcon={startIcon}
  >Send Hot Jobs Email</Button>

</div>


    </div>
  }


  public render() {

    console.log('DailyEmails render');

    var state = this.props;
    var vm = state.vm;
    //var searchFilter = state.searchFilter;


    var keyword = '';

    return <App.MainPage
      pageTitle="Administration > Daily Emails"
      state={vm}
     // hasDataLoaded={state.hasDataLoaded}
     // loading={(this.adminEmailService.isServiceCallPending && !vm.isActionInProgress)}
      showContentWhenInProgress={true}
      alignError='left'
      isChildPage={true}
    >
      <div>

        {this.getForm()}


      </div>



    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.administrationState })
)(DailyEmails as any);

