import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


import { AdministrationUsersTable } from './AdministrationUsersTable';
import { AdministrationUsersEditModal } from './AdministrationUsersEditModal';
import { AdministrationUsersCreateModal } from './AdministrationUsersCreateModal';
import { AdministrationUsersChangePasswordModal } from './AdministrationUsersChangePasswordModal';
import { AdministrationUsersEditRolesModal } from './AdministrationUsersEditRolesModal';


class AdministrationUsers extends React.Component<App.AdministrationState, {}> {

  public adminUsersService: App.AdminUsersService;

  constructor(props: any) {
    super(props);
    this.adminUsersService = new App.AdminUsersService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationUsers componentDidMount');

    if (!this.props.hasUserDataLoaded) {
      console.log('AdministrationUsers getUsersListData');

      App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_USERS_LIST_PAGE_DATA_REQUEST, null);
 

    }


  }

  public componentWillUnmount() {
    console.log("componentWillUnmount");
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_USERS_LIST_PAGE_RESET,null);
    
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationUsers componentDidUpdate');


  }

  public onCreateClick() {
    console.log('onCreateClick');
    var state = this.props;
    var vm = state.vm;

    //vm.setSelectedUser();
    vm.setSelectedUserCreate();

    vm.isUsersAddOpen = true;
    this.forceUpdate();


  }

  public onInputChange(newVal: any) {
    console.log('onInputChange:' + newVal);


  }

 
  public onSearchClick() {
    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.appUsersSearchFilter;
    searchFilter.isSearch=true;
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_USERS_LIST_PAGE_DATA_REQUEST, searchFilter);

  }

  public onTest() {



    var location = App.AppBase.location;

    console.log('onTest location', location);


    var params = App.AppBase.params;

    console.log('onTest params', params);

    App.RouteManager.routeToPath('?requestResumeId=67519');



    console.log('onTest location2', location);
    console.log('onTest params2', params);

    this.forceUpdate();
  }

  public onCancelClick(){
    var state = this.props;
    var vm = state.vm;
    vm.isUsersDeleteOpen=false;
    vm.apiErrorMessage = '';

    this.forceUpdate();
  }

  public onDeleteConfirmClick() {
    console.log('onDeleteConfirmClick');

    var state = this.props;
    var vm = state.vm;

    var itemId = vm.selectedUser?.userId;

    console.log('onDeleteConfirmClick itemId', itemId);

    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_USERS_DELETE_REQUEST, itemId);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }

  public render() {

    console.log('AdministrationUsers render');

    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.appUsersSearchFilter;

    console.log('AdministrationUsersList hasDataLoaded', state.hasDataLoaded);
    console.log('AdministrationUsersList isServiceCallPending', this.adminUsersService.isServiceCallPending);
    console.log('AdministrationUsersList vm.isActionInProgress', vm.isActionInProgress);

    var keyword = '';


    var createBtn = <Button
      id="btnCreate"
      variant="contained"
      style={{ marginBottom: 10 }}
      color="primary"
      onClick={() => this.onCreateClick()}
    >Create</Button>

    return <App.MainPage
      pageTitle="Administration > Users"
      state={vm}
      hasDataLoaded={state.hasUserDataLoaded}
      loading={(state.isServiceCallPending && !vm.isActionInProgress)}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      alignError='left'
      isChildPage={true}
    >
      <div>

        {<LIB.MSearchBar
          filter={searchFilter}
          onSearchClick={() => this.onSearchClick()}
          isSearchInProgress={state.isServiceCallPending}
        />}

        <div style={{ textAlign: 'right', width: '100%' }} >

          {createBtn}

        </div>

        <AdministrationUsersTable state={state} onChange={() => this.forceUpdate()} />

        <AdministrationUsersEditModal state={state} onChange={() => this.forceUpdate()} />

        <AdministrationUsersCreateModal state={state} onChange={() => this.forceUpdate()} />

        <AdministrationUsersChangePasswordModal state={state} onChange={() => this.forceUpdate()} />

        <AdministrationUsersEditRolesModal state={state} onChange={() => this.forceUpdate()} />

        

      </div>

      

<LIB.ConfirmModal 
    visible={vm.isUsersDeleteOpen}
    onCancelClick={ ()=>this.onCancelClick()}
    onConfirmClick={()=>this.onDeleteConfirmClick()}
    title={`Confirm Delete: ${vm.selectedUser?.fullName} (${vm.selectedUser?.userId})`}
    content={`Are you sure you want to delete user "${vm.selectedUser?.fullName} (${vm.selectedUser?.userId})" ?`}
    confirmButtonLabel="Delete" 
    errorMessage={vm.apiErrorMessage}
    disabled={vm.isActionInProgress}
    />

    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.administrationState })
)(AdministrationUsers as any);

