import * as App from 'AppReferences';
import * as LIB from '_LIB';

export class GlobalUIStateVm  extends LIB.BaseVmModel {
 
 
    //public isImpersonateModalOpen:boolean;
    public isAdminImpersonateModalOpen:boolean=false;
    public isMobile:boolean=false;
    public isMobileMenuOpen:boolean=false;
    public selectedConstructionRootMenuIds:any;
    public selectedConstructionMenuIds:string[]=[]; 


    constructor() { 
 
        super();
       // this.isImpersonateModalOpen = false;

    }
     


}